import React  from 'react'
import { useHistory, useParams } from 'react-router';
import Locale from '../../../../translations';


function Traveler({traveler ,index}) {
    let history = useHistory();
    let {id}=useParams();
    const { translate } = Locale;
    return (
      <>
      <tr>
        <td>{traveler?.reservation_no}</td>
        <td>
        {traveler?.test_type} <br/>
        
        </td>
        <td>{traveler?.traveller_name?.ar}</td>
        <td>{traveler.company_name} </td>
        <td>
          {traveler.reservation_type}
          
        </td>
        <td>
        <div className="d-flex flex-column">
            <span>{(traveler.check_date)?.slice(0,10)} </span>
            <span>{(traveler.check_date)?.slice(11,16)}</span>
          </div>
        </td>

        <td>
         <div className="d-flex flex-column">
            <span> {(traveler.request_date)?.slice(0,10)} </span>
            <span>{(traveler.request_date)?.slice(11,16)}  </span>
          </div>
        </td>
        <td>{traveler.amount} {translate.pound}</td>
        
       
       
        
        <td className="text-orange"> {traveler.comission} {translate.Pound} </td>
        <td className="text-orange">  {traveler.safa_comission} {translate.Pound}</td>
        <td>
          <button className="text-primary " onClick={() => {
            history.push(`/admin/ordersList/incoming/view/${id}/order/${traveler.id}`);
          }}><i class="fas fa-chevron-left"></i>  </button>
        </td>


 {/* Modal examainaton type */}
 <div>

</div>
      </tr>





</>
    );
}

export default Traveler;
