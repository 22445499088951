import { useHistory } from "react-router";
function reducers(state, action) {
  

  switch (action.type) {

    case "setLoading":
      return { ...state, loading: action.payload };

    case "setLocale":
      localStorage.setItem("currentLanguage", action.payload);
      window.location.reload();
      return { ...state, currentLanguage: action.payload };
    case "logout":
      localStorage.removeItem("isAuth");
      localStorage.removeItem("token");
      localStorage.removeItem("userType");
      /*** */
      // setTimeout(() => {
      //   window.location.reload();
      // }, 5500);
    window.location.reload();

      

      return {...state};
    case "login":
      
      localStorage.setItem("isAuth", true);
      localStorage.setItem("token", action.payload.access_token);
      localStorage.setItem("userType", action.payload.user_type);
      localStorage.setItem("userType", action.payload.user_type);
      localStorage.setItem("isAdmin", action.payload.isAdmin);
      localStorage.setItem("permissions",JSON.stringify(action.payload.permissions) );
      return { ...state, 
        isAuth: true,
        token:action.payload.access_token,
        userType:action.payload.user_type,
        isAdmin:action.payload.isAdmin,
        permissions:action.payload.permissions };
    
      default:{
        throw new Error(`Unhandled action type: ${action.type}`);
      }
  }
}

export default reducers;
