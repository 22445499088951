import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import Locale from "../../../translations";

function ComanyMedical({ changeStatusUser, allData }) {
  let history = useHistory();

  const { translate } = Locale;
  return (
    <>
      <tr className="comany-medical">
        <td>{allData?.name}</td>
        <td>{allData?.country}</td>
        <td>{allData?.city} </td>
        <td>{allData?.user_name}</td>

        <td>{allData.phone_number} </td>

       <td><i class="fas fa-eye" style={{fontSize:"14px"}}></i> <span className="font-weight-bold">{allData.total_reservations}</span></td>
        {allData.status == "active" ? (
          <td className="text-success">فعال</td>
        ) : (
          <td className="text-danger">غير مفعل</td>
        )}
        <td className="actions">
          <button
            className="text-primary mr-3 btn-link"
            onClick={() => {
              history.push(`/admin/ComanyMedical-/${allData.id}`);
            }}
          >
            <i class="fas fa-eye"></i> {translate.view}
          </button>
          {/* ************************edit******************* */}

          <button
            className="text-primary  btn-link"
            onClick={() => {
              history.push(`/admin/ComanyMedical-edit/${allData.id}`);
            }}
          >
            <i className="fas fa-edit"></i> {translate.edit}{" "}
          </button>
          {/*********************tswia**************************/}

          {/* <button
            className="text-primary  btn-link"
            onClick={() => {
              history.push(`/admin/payment/${allData.id}`);
            }}
          >
            <i class="fas fa-money-bill-wave"></i> {translate.Settlement}{" "}
          </button> */}

          {/* ************************Orders******************* */}
          <button
            className="text-primary mr-3  btn-link"
            onClick={() => {
              history.push(`/admin/ordersList/incoming/${allData.id}`);
            }}
          >
            <i class="fas fa-th-list"></i> {translate.orders}
          </button>

          {/* ************************Users******************* */}
          {/* <button
            className="text-primary mr-3  btn-link"
            onClick={() => {
              history.push(`/admin/users/${allData.id}`);
            }}
          >
            <i class="fas fa-user-friends "></i> {translate.users}
          </button> */}

          {/* ************************changeStatusUser******************* */}

          {allData.status === "active" ? (
            <button
              type="button"
              onClick={() => changeStatusUser(allData.id, "deactivate")}
              className="btn btn-link text-danger"
            >
              <i class="fas fa-stop-circle"></i> {translate.deactive}
            </button>
          ) : (
            <button
              type="button"
              onClick={() => changeStatusUser(allData.id, "activate")}
              className="btn btn-link text-success"
            >
              <i class="fas fa-play-circle"></i> {translate.active}
            </button>
          )}

          {/*  */}
        </td>

        {/* Modal examainaton type */}
        <div></div>
      </tr>
    </>
  );
}

export default ComanyMedical;
