import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {  useState } from "react";
import { useCompanyDispatch, useCompanyState } from "../../context/global";

import arLogo from "../../assets/images/ar-flag.svg";
import enLogo from "../../assets/images/en-flag.svg";
import Locale from "../../translations";

function UserSetting() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const dispatch = useCompanyDispatch();
  const { currentLanguage } = useCompanyState();
      const { translate } = Locale;
  return (
    <>
      <UncontrolledDropdown setActiveFromChild>
        <DropdownToggle tag="button" className="h5 text-primary d-inline" caret>
          <i className="fas fa-cog fa-lg  mx-2 text-primary "></i>{" "}
          {translate.setting}

        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            tag="button"
            onClick={() => {
              dispatch({ type: "setLocale", payload: "ar" });
            }}
          >
            <img className=" px-2" src={arLogo} width="40" alt="lang" />
            العربيه
          </DropdownItem>
          {/* <DropdownItem
            tag="button"
            onClick={() => {
              dispatch({ type: "setLocale", payload: "en" });
            }}
          >
            <img className=" px-2" src={enLogo} width="40" alt="lang" />
            English
          </DropdownItem> */}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default UserSetting;
