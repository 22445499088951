import React, { useEffect, useState } from "react";
import { listDoneTravellers } from "../../../../services/yarvel";
import Locale from "../../../../translations";
import TravelerCheched from "./travelerChecked";


export default function ListTravelersCheched({allData}) {
  const { translate } = Locale;
//console.log("allDataallData",allData)
  // call api for progress
//

const [employee, setEmployee] = useState([]);

    
  useEffect(() => {
    async function fetchData() {
      const Employees = await listDoneTravellers();
      setEmployee(Employees?.data?.data);

    }
    fetchData();

    
    //console.log("employee is ",employee)
  }, []);
  // call api for checked
  const AllTravelers =
  allData?.data?.length > 0
    ? allData?.data.map((employee, index) => (
        <TravelerCheched
        employee={employee}
          index={index}
        />
      ))
    : [];
    //console.log("AllDoneTravelers is ",AllTravelers)
  // call api for checked

  return (
    < >
      <table className="table table-travelers">
        <thead className="bg_table ">
          <tr className="table_head">
            <th scope="col">{translate.RunCommandNumber}</th>
            <th scope="col">{translate.ExaminationType}</th>
            <th scope="col">{translate.traveler}</th>
            <th scope="col">{translate.examinationMethod}</th>
            <th scope="col">{translate.DateRequest}</th>
            {/* <th scope="col">{translate.number}</th>
            <th scope="col">{translate.Cost}</th> */}

          
            <th scope="col">{translate.timeRequest}</th>
            <th scope="col">{translate.DataOperation}</th>
            <th scope="col">{translate.ResultTime}</th>
            <th scope="col">{translate.tools}</th>
          </tr>
        </thead>
        <tbody>
          {/*
          <TravelerCheched />
          <TravelerCheched />
          <TravelerCheched />
          <TravelerCheched />
          <TravelerCheched />
          <TravelerCheched />
          <TravelerCheched />
          <TravelerCheched />
          <TravelerCheched />
          */}
          {AllTravelers.length > 0 ? (
												AllTravelers
											) : (
												<tr>
													<td colSpan="11" className="text-center my-3">
														<div className="product-build__product-no-data">
															<i className="fas fa-info-circle fa-lg"></i>{" "}
															<h4>{translate.noResult}</h4>
														</div>
													</td>
												</tr>
											)}
          
          
        </tbody>
      </table>
      {/*       <Pagination /> */}
    </>
  );
}
