import React, { useEffect, useState } from "react";
import Locale from "../../../../translations";
import { NavLink, useParams } from "react-router-dom";
import ListTravelersCheched from "./listTravelersCheched";
import FilterChecked from "./filterChecked";
import moment from "moment";
import { listCheckedOrder } from "../../../../services/admin";

function OrdersChecked() {
 
  const { translate } = Locale;
 
 /** */
 const { id }= useParams();
 const [filter, setfilter] = useState({
   name: "",
   departure_date: "",
   check_date: "",
 });

 const [searching, setSearching] = useState(false);
 const [allData, setAllData] = useState([]);

 const submit = () => {
   setSearching(!searching);
 };

 useEffect(() => {
   async function fetchListClients() {
     const res = await listCheckedOrder(id,{
       ...filter,
       departure_date: filter.departure_date
         ? moment(filter.departure_date).format("YYYY-MM-DD")
         : "",
       check_date: filter.check_date
         ? moment(filter.check_date).format("YYYY-MM-DD")
         : "",
     });
     setAllData(res?.data?.data);
   }
   fetchListClients();
 }, [searching]);

 console.log("setData=>*******",allData);
  /** */
  

  return (
    <section>
      <div className="container p-0 my-4 ">
        

        <header className=" list_header border py-2 px-2 d-flex justify-content-between align-items-baseline">
          <h3 className="text-muted font-weight-bold">{translate.Customers}</h3>
          <div>
             <NavLink
                exact={true}
                to={`/admin/ordersList/incoming/${id}`}
                activeClassName="is-active"
                className=" tab-link py-2 px-4 text-bold bg-white mr-3 bg-brwon rounded text-muted"
              >
                {translate.IncomingRequests}
              </NavLink>
              <NavLink
                exact={true}
                to={`/admin/ordersList/progress/${id}`}
                activeClassName="is-active"
                className="tab-link  py-2 px-4 text-bold bg-white mr-3 bg-brwon rounded text-muted"
              >
                {translate.InProgress}
              </NavLink>

              <NavLink
                exact={true}
                to={`/admin/ordersList/checked/${id}`}
                activeClassName="is-active"
                className=" tab-link py-2 px-4 text-bold bg-white rounded text-muted"
              >
                {translate.AreChecked}
              </NavLink>
          </div>
        </header>
        <FilterChecked  submit={submit} filter={filter} setfilter={setfilter}/>
        <ListTravelersCheched  allData={allData}/>
      </div>

      
    </section>
  );
}

export default OrdersChecked;
