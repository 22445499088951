import React, { useEffect } from "react";
import Activity from "./Activity";
import Counts from "./Counts";
import TravelersNumber from "./TravelersNumber";
import ApexChart from "./chart";
import { useState } from "react";
//import { dashboardList } from "../../services/yarvel";
import Locale from "../../../translations";
import { dashboard } from "../../../services/admin";

export default function DashboardCompaniesAdmin() {
  const { translate } = Locale;

  /************Api */
  
  const [dashboardData, setDashboardData] = useState();

  let data = [
		{
			id: 1,
			time: "11:58",
			city: "Cairo Egypt",
			type: "Chrome | Mac Os X 10.15.3 | 41.36.21",
      date:"Mar 15, 2021"
		},
		{
			id: 2,
			time: "15:58",
			city: "Cairo Egypt",
			type: "Chrome | Mac Os X 10.15.3 | 41.36.21",
      date:"Mar 16, 2021"
		}
	];

  useEffect(() => {
    async function fetchData() {
      const res = await dashboard();
      setDashboardData(res?.data);
      console.log("dashboardData",res?.data)
    }
    fetchData();
    
  }, []);

  const all =
  dashboardData &&
  dashboardData.logs &&
  Object.entries(dashboardData?.logs).length > 0
    ? Object.entries(dashboardData?.logs).map((data) => (
        <Activity data={data} />
      ))
    : "";
//dashboard

  
  /***********Api  */
  return (
    <>
      <section className="dashboard-companies">
        <div className="container">
          <div className="row  pt-4">
            <div className="col-md-3">
              <Counts users={dashboardData?.users} companies={dashboardData?.companies} />
            </div>
            <div className="col-md-6">
              <div className="bg-white shadow-sm h-100  p-2 font-weight-bold orders">
                <p className="text">
                  <TravelersNumber />
                </p>
                <div className="row my-5">
                  <div className="col-7">
                    {" "}
                     <ApexChart num={dashboardData} /> 
                  </div>
                  <div className="col-4 d-flex flex-column  justify-content-center">
                    <div className="mt-3">
                      <h4>
                        <i
                          className="fas fa-circle mx-3"
                          style={{ color: "#24AEFB", fontSize: "15px " }}
                        ></i>
                        {translate.IncomingRequests}
                      </h4>
                      <h4 className="mx-5">
                         {dashboardData?.reservations?.new} 
                      </h4>
                    </div>
                    <div className="mt-3">
                      <h4>
                        <i
                          className="fas fa-circle mx-3"
                          style={{ color: "#F28F31", fontSize: "15px " }}
                        ></i>
                        {translate.InProgress}
                      </h4>
                      <h4 className="mx-5">
                         {dashboardData?.reservations?.pending} 
                       
                      </h4>
                    </div>
                    <div className="mt-3">
                      <h4>
                        <i
                          className="fas fa-circle mx-3"
                          style={{ color: "#00B536", fontSize: "15px " }}
                        ></i>{" "}
                        {translate.AreChecked}{" "}
                      </h4>
                      <h4 className="mx-5">
                       {dashboardData?.reservations?.checked} 
                        
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="p-2 activity bg-white shadow-sm rounded">
                <div className="title">
                  <h2 className="font-weight-bold text-subtitle">
                    {translate.myActivity}
                  </h2>
                  <p className="text">{translate.activityText}</p>
                </div>
                  {/**login **/}
                  {/* <Activity data={data} /> */}
                  {all}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
