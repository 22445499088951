import React, { useEffect, useState } from "react";
import Locale from "../../../../translations";
import { useHistory, useParams } from 'react-router';
import { listTravellersById } from "../../../../services/yarvel";
export default function View() {
  const { translate } = Locale;
  let history = useHistory();

//listTravellersById
const [employee, setEmployee] = useState([]);
let { id } = useParams();
useEffect(() => {
  async function fetchData() {
    const Employees = await listTravellersById(id);
    setEmployee(Employees.data);
  }
  fetchData();
}, []);


  return (
    <div className="container p-0">
      <header className=" list_header border py-3 px-2 d-flex justify-content-between align-items-baseline mt-4">
        <h3 className="text-muted font-weight-bold">
        فحص PCR
        </h3>
        <button className="btn btn-primary  font-weight-bold " onClick={() => {
              history.push("/CustomersList/orders");
              }}><i class="fas fa-print" style={{fontSize:"16px"}}></i>  {translate.SupplyingRunningOrder} </button>
      </header>
      

      <div className="bg-white px-5 py-4">
        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.orderData}
        </h5>

        <div className="instructions py-3 p-2 row">
        <p className="col-2 h5  py-1 font-weight-bold">
            {translate.check}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.test_type}</p>
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.orderNumber}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.reservation_no}</p>
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.typeCheck}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.reservation_type}</p>

          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.timeRequest}
          </p>
          <p className="col-4 h5 py-1">{(employee?.data?.request_date)?.slice(0,10)} | {(employee?.data?.request_date)?.slice(11,16)}</p>


          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.serviceDegree}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.grade}</p>

          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.ResultTimeResult}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.check_date_period} {translate.hour}</p>
          
        </div>
 {/* *************************************************************************/}
 <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.TripData}
        </h5>

        <div className="instructions py-3 p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.destnation}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.trip?.destination?.names?.ar}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.departureDate}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.trip?.arrival_date}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.Transporter}
          </p>
          <p className="col-4 h5 py-1"> {employee?.data?.trip?.transporter?.ar}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.departureDate}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.trip?.departure_date}</p>
        </div>
        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.CustomerData}
        </h5>

        <div className="instructions py-3 p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.nameArabic}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.traveller_name_ar}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.nationalId}
          </p>
          <p className="col-4 h5 py-1">   {employee?.data?.national_id  }  </p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.EnglishName}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.traveller_name_en}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.passportNumber}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.passport_number}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.Nationality}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.nationality}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.phoneNumber}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.phone}</p>
        </div>

       

        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.ExaminationData}
        </h5>

        <div className="instructions py-3 p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.examinationStatus}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.test_type}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.ServiceGrade}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.grade}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.Transporter}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.reservation_type}</p>
        </div>

        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.Cost}
        </h5>

        <div className="instructions py-3 p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.TotalCost}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.amount} {translate.pound}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.Paid}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.amount} {translate.pound}</p>
          
        </div>

{/* <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.ExaminationData}
        </h5>

        <div className="instructions py-3 p-2 rounded-lg row">
          <p className="col-2 h5  py-3 font-weight-bold">
            {translate.date} {translate.check}
          </p>
          <p className="col-4 h5 py-3">{employee?.check_date?.slice(11, 16)}  |  {employee?.check_date?.slice(0, 10)}</p>
          <p className="col-2 h5  py-3 font-weight-bold">
            {translate.ResultTime}
          </p>
          <p className="col-4 h5 py-3">
              {employee?.check_date?.slice(11, 16)} - {employee?.check_date_end?.slice(11, 16)}
          </p>

          <p className="col-2 h5  py-3 font-weight-bold">
            {translate.governorate}
          </p>
          <p className="col-4 h5 py-3">-</p>
          <p className="col-2 h5  py-3 font-weight-bold">{translate.city}</p>
          <p className="col-4 h5 py-3">{employee?.city_id}</p>

          <p className="col-2 h5  py-3 font-weight-bold">الشارع</p>
          <p className="col-4 h5 py-3">{employee?.street}</p>
          <p className="col-2 h5  py-3 font-weight-bold">
            {translate.BuildingNumber}
          </p>
          <p className="col-4 h5 py-3">{employee?.building}</p>

          <p className="col-2 h5  py-3 font-weight-bold">الدور</p>
          <p className="col-4 h5 py-3">{employee?.floor}</p>
          <p className="col-2 h5 py-3 font-weight-bold">رقم الشقه</p>
          <p className="col-4 h5 py-3">{employee?.flat}</p>
          <p className="col-2  h5 py-3 font-weight-bold">
            {translate.phoneNumber}
          </p>
          <p className="col-4 h5 py-3">{employee?.phone}</p>
          <p className="col-2  h5 py-3 font-weight-bold">{translate.email}</p>
          <p className="col-4 h5 py-3">{employee?.email}</p>

          <p className="col-2  h5 py-3 font-weight-bold">{translate.Cost}</p>
          <p className="col-4 h5 py-3">
            {employee?.amount}
            {translate.pound}
          </p>
          <p className="col-2  h5 py-3 font-weight-bold">
            {translate.paymentMethods}
          </p>
          <p className="col-4 h5 py-3">-</p>
        </div> */}

      </div>




      <button className=" btn btn-secondary px-5 my-4 font-weight-bold" onClick={() => {
              history.push("/CustomersList/orders");
              }}> {translate.back}</button>
    </div>

 
    
  );
}
