import React from 'react'
import { useHistory } from 'react-router';
import Locale from "../../../../translations";


function TravelerCheched({employee}) {
    let history = useHistory();
    const { translate } = Locale;

  

 
    return (
      <>
      <tr className="border-green">
        <td>{employee.reservation_no}</td>
        <td>{employee.test_type}</td>
        <td>
        {employee?.traveller_name?.ar} <br/>
        {employee?.traveller_name?.en}
        </td>
        <td>{employee.reservation_type}</td>
        <td>
          <div className="d-flex flex-column">
            <span> {(employee?.check_date)?.slice(0,10)} </span>
            <span>{(employee?.check_date)?.slice(11,16)}</span>
          </div>
        </td>
        {/* <td>2</td>
        <td>{employee.total_cost} ج.م</td> */}
        
        <td>
          <div className="d-flex flex-column">
            <span>{(employee?.request_date)?.slice(0,10)}   </span>
            <span>{(employee?.request_date)?.slice(11,16)}</span>
            </div>
          </td>
        <td>
        <div className="d-flex flex-column">
            <span>{(employee?.operationOrder_created_at)?.slice(0,10)}   </span>
            <span>{(employee?.operationOrder_created_at)?.slice(11,16)}</span>
          </div>
        </td>

        <td>
        24 ساعة
        </td>
        <td>
          <button className="text-primary  btn-link font-weight-bold mr-3" onClick={() => {
            history.push(`/CustomersList/view-Cheched/${employee?.id}`);
          }}><i class="fas fa-eye" style={{fontSize:"16px"}}></i>  {translate.view}</button>

        </td>
      </tr>





 
</>
    );
}

export default TravelerCheched;
