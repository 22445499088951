
import React,{useState} from "react";
import Setting from ".";
import Locale from "../../../translations";
import AddSpecialSettings from "./addspecialSettings";
export default function SpecialSettings({setting,setSetting}){
    const { translate } = Locale;

    const deleteFn =(index)=>{
      setting.capacities.splice(index,1)
      setSetting({...setting,capacities: setting.capacities})
      
      {/*********SetCompany******* */}
    }

    const add=()=>{
      setSetting({...setting, capacities:[...setting.capacities,{}]})
    }
  
    return (
        <>
          
        <div className="container py-5 bg-white " >
          {setting?.capacities && setting?.capacities?.length > 0 ? setting?.capacities?.map((res,index)=>
          {
          return <AddSpecialSettings key={index+"asdasd"} index={index}  data={res} deleteFn={deleteFn} setting={setting} setSetting={setSetting} />}
          ) : null}

              <div className="text-center"> 
                <button className="btn text-success btn-lg " onClick={add}><i class="fas fa-plus-circle"></i> {translate.add} {translate.SpecialSettings}</button>
              </div>
        </div>



      </>
    );
}