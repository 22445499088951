import React, { useEffect, useState } from "react";
import Locale from "../../../translations";

import TextField from "../../../components/shared/textField";
import { NavLink ,useHistory } from "react-router-dom";
import validate, { isFormValid } from "../../../helpers/validate";
import { store } from "react-notifications-component";
import {  useParams } from 'react-router';

import {
  editCompany, fetchCities, fetchCountries, viewCompany, 
} from "../../../services/admin";
import SelectField from "../../../components/shared/SelectField";
import Services from "./services";

export default function EditCompany() {
  const { translate } = Locale;
  let history = useHistory();

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  /* */
  const locale = localStorage.getItem("currentLanguage") || "en";
  let { id } = useParams();


  const [changePassword, setChangePassword] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  const handleChange = (e) => {
	  
    if ("target" in e) {
      const value = e.target.value;
      const name = e.target.name;
      setChangePassword({ ...changePassword, [name]: value });
    }else{
		const value = e.value;
		const name = e.name;
		setChangePassword({ ...changePassword, [name]: value });
	}
  };

  /************edit************* */
  const [errors, setErrors] = useState({});
  

  const checkFormErrors = () => {
    setErrors({
      ...errors,
      ...validate(
        {
          name: "name",
          value: changePassword.name,
        },
        {
          required: true,
        }
      ),
      ...validate(
        {
          name: "country_id",
          value: changePassword.country_id,
        },
        {
          required: true,
        }
      ),
      ...validate(
        {
          name: "city_id",
          value: changePassword.city_id,
        },
        {
          required: true,
        }
      ),
      ...validate(
        {
          name: "user_name",
          value: changePassword.user_name,
        },
        {
          //required: true,
        }
      ),

      ...validate(
        {
          name: "phone_number",
          value: changePassword.phone_number,
        },
        {
          required: true,
        }
      ),
      ...validate(
        {
          name: "email",
          value: changePassword.email,
        },
        {
          	required: true,
        }
      ),
      ...validate(
        {
          name: "address",
          value: changePassword.address,
        },
        {
          	required: true,
        }
      ),

// *********
      ...validate(
        {
          name: "user_email",
          value: changePassword.user_email,
        },
        {
          	required: true,
        }
      ),
      ...validate(
        {
          name: "domain_name",
          value: changePassword.domain_name,
        },
        {
          	required: true,
        }
      ),
      ...validate(
        {
          name: "website",
          value: changePassword.website,
        },
        {
          	required: true,
        }
      ),
    });
  };

  const handleForm = async () => {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  };
  useEffect(() => {
    async function fetchLookups() {
      const res = await fetchCountries();
      const format = res.map((t) => ({
        value: t.id,
        label: t.names[locale],
      }));
  
      setCountries(format);
    }
    fetchLookups();
  }, []);

  useEffect(() => {
    async function fetchLookups() {
      
      if (changePassword.country_id?.value) {
        const res = await fetchCities(changePassword.country_id?.value);
        
        const formatted = res.map((country) => ({
          value: country.id,
          label: country.names[locale],
        }));
        setCities(formatted);
      }
    }
    fetchLookups();
  }, [changePassword.country_id]);

  
 useEffect(() => {
  async function fetchData() {
    const res = await viewCompany(id);

   let map= res.data.data.services.map(element =>{return {...element,
     check_id:{label:element.check,value:element.check_id},
     type_id:{label:element.type,value:element.type_id},
     check_grade_id:{label:element.grade,value:element.check_grade_id},
     comission_type:{label:element.comission_type,value:element.comission_type}
  }});
    setChangePassword({...changePassword,
      ...res?.data?.data ,services:map,
      country_id:{label:res?.data?.data?.country?.names?.en,value:res?.data?.data?.country?.id },
      city_id:{label:res?.data?.data?.city?.names?.en,value:res?.data?.data?.city?.id },
      user_email:res?.data?.data.user.email
    
    });
  }
  fetchData();
 
}, []);
console.log(":changePasswordchangePasswordchangePassword",changePassword);

  useEffect(() => {

    const editCompany1 = async () => {
        if (isFormValid(errors)) {
          let map1= changePassword?.services.map(element =>{return {...element,
            check_id:element.check_id.value,
            type_id:element.type_id.value,
            check_grade_id:element.check_grade_id.value,
            comission_type:element.comission_type.value,
          }});
        const res = await editCompany(id,{...changePassword,country_id:changePassword.country_id.value,city_id:changePassword.city_id.value,services:map1});
        if (res.status == 201) {
          store.addNotification({
            title: "info!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
              pauseOnHover: true,
            },
          });

          history.push("/admin/ComanyMedical")
        }
     }
    };

    editCompany1();
  }, [isErrorLoaded]);
  /*** */
  return (
    <section>
      <div className="container p-0 my-4 ">
        <header className=" list_header border py-3 px-3">
          <div className="d-flex justify-content-between  ">
            <h3 className="text-muted font-weight-bold">{translate.addComanyMedical}</h3>
          </div>
        </header>

        <div className=" controls">
          <div className="row my-3">
            <div className="col-3 mr-3">
              <TextField
                type="text"
                hasLabel={true}
                label={translate.companyName}
                //placeholder={translate.companyName}
                name="name"
                onChange={(e) => {
                  setChangePassword({
                    ...changePassword,
                    name: e.target.value,
                  });
                }}
                color={errors?.name?.required ? "danger" : ""}
                errors={errors?.name}
                value={changePassword.name}
              />
            </div>


            <div className="col-3 mx-3">
              <SelectField
                type="text"
                name="country_id"
                label={translate.country}
                placeholder={translate.country}
                errors={errors.country_id}
                color={errors.country_id?.required ? "danger" : ""}
                options={countries}
                onChange={(e) => {
                  
					      handleChange({ name: "country_id", value: e });

                setErrors({
                  ...errors,
                  ...validate(
                    { name: "country_id", value: e },
                    {
                      required: true,
                    }
                  ),
                });
                }}
                 value={changePassword?.country_id}
              />
            </div>
            <div className="col-3 mx-3">
			<SelectField
											type="text"
											name="city_id"
											label={translate.city}
											placeholder={translate.city}
											errors={errors.city_id}
											color={errors.city_id?.required ? "danger" : ""}
											options={cities}
											onChange={(e) => {
												handleChange({ name: "city_id", value: e });
												setErrors({
													...errors,
													...validate(
														{ name: "city_id", value: e },
														{
															required: true,
														}
													),
												});
											}}
											value={changePassword?.city_id}
										/>
            </div>
          </div>

          <div className="row">
            <div className="col-3 mr-3">
              <TextField
                type="text"
                hasLabel={true}
                label={translate.nameOperation}
                name="user_name"
                onChange={(e) => {
                  setChangePassword({
                    ...changePassword,
                    user_name: e.target.value,
                  });
                  
                }}
                color={errors?.user_name?.required ? "danger" : ""}
                errors={errors?.user_name}
                value={changePassword?.user?.name}              />
            </div>

            <div className="col-3 mx-3">
			<TextField
                type="text"
                hasLabel={true}
                label={translate.emailOperation}
                placeholder={translate.email}
                name="user_email"
                onChange={(e) => {
                  setChangePassword({
                    ...changePassword,
                    user_email: e.target.value,
                  });
                }}
                
                color={errors?.user_email?.required ? "danger" : ""}
                errors={errors?.user_email}
                value={changePassword?.user_email} 
              />
            </div>

            <div className="col-3 mx-3">
			<TextField
                type="text"
                hasLabel={true}
                label={translate.phoneNumber}
                placeholder={translate.phone_number}
                name="phone_number"
                onChange={(e) => {
                  setChangePassword({
                    ...changePassword,
                    phone_number: e.target.value,
                  });
                }}
                color={errors?.phone_number?.required ? "danger" : ""}
                errors={errors?.phone_number}
                value={changePassword?.phone_number} 
              />
            </div>
			<div className="col-3 my-3">
			<TextField
                type="text"
                hasLabel={true}
                label={translate.address}
                placeholder={translate.address}
                name="address"
                onChange={(e) => {
                  setChangePassword({
                    ...changePassword,
                    address: e.target.value,
                  });
                }}
                color={errors?.address?.required ? "danger" : ""}
                errors={errors?.address}
                value={changePassword?.address} 
              />
            </div>
            {/********************** */}
            
            <div className="col-3 mx-4 my-3">
			    <TextField
                type="text"
                hasLabel={true}
                label={translate.userEmail}
                placeholder={translate.userEmail}
                name="email"
                onChange={(e) => {
                  setChangePassword({
                    ...changePassword,
                    email: e.target.value,
                  });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "email", value: e.target.value },
                      {
                        required: true,
                      }
                    ),
                  });
                }}
                color={errors?.email?.required ? "danger" : ""}
                errors={errors?.email}
                value={changePassword?.email} 
              />
            </div>


            <div className="col-3 mx-3 my-3">
			<TextField
                type="text"
                hasLabel={true}
                label={translate.domainName}
                placeholder={translate.domainName}
                name="domain_name"
                onChange={(e) => {
                  setChangePassword({
                    ...changePassword,
                    domain_name: e.target.value,
                  });
                }}
                color={errors?.domain_name?.required ? "danger" : ""}
                errors={errors?.domain_name}
                value={changePassword?.domain_name} 
              />
            </div>

            <div className="col-3 mr-3">
			<TextField
                type="text"
                hasLabel={true}
                label={translate.website}
                placeholder={translate.website}
                name="website"
                onChange={(e) => {
                  setChangePassword({
                    ...changePassword,
                    website: e.target.value,
                  });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "website", value: e.target.value },
                      {
                        required: true,
                      }
                    ),
                  });
                }}
                color={errors?.website?.required ? "danger" : ""}
                errors={errors?.website}
                value={changePassword?.website} 
              />
            </div>

            {/******************************* */}
          </div>
        </div>

        
      </div>




      <div className="container p-0 my-4 ">
        <header className=" list_header border py-3 px-3">
          <div className="d-flex justify-content-between  ">
            <h3 className="text-muted font-weight-bold">{translate.services}</h3>
          </div>
        </header>

        <Services  company={changePassword}  setCompany={setChangePassword} />  {/***/}


{/********************** */}


        <div className=" btns d-flex justify-content-between align align-items-end ">
          <NavLink
            exact={true}
            to={`/admin/ComanyMedical`}
            activeClassName="is-active"
          >
            <button className="btn btn-secondary px-5 py-2 ">
              {translate.back}
            </button>
          </NavLink>
          <button className="btn btn-success px-5 py-2 " onClick={
                handleForm
                }>
            {translate.edit}
          </button>
        </div>
      </div>
      
    </section>
  );
}
