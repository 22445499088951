import React from "react";
import SelectField from "../../../components/shared/SelectField";
import TextField from "../../../components/shared/textField";
import Locale from "../../../translations";
import { Link, NavLink } from "react-router-dom";


export default function Filter({filter,setfilter,users,setUsers,submit}) {
  const { translate } = Locale;
  const filterEmpty=((filter)=>{
    setfilter({
      name:"", 
      status:""
    })
    //submit();
  })

  //const active=["active","inactive"]
  //const active={value:"active",value1:"inactive"}
  const active=[ {value: 1, label: "active"},{value: 0, label: "inactive"}]
  
  return (
    <>
    <div className="row no-gutters list_header" style={{width:"60% "}}>
      <div className="col mr-2 ">
        <TextField
          hasLabel={false}
          label={translate.search}
          type={"text"}
          placeholder={translate.search}
          value={filter?.name}
          onChange={(e) =>{
            setfilter({
              ...filter,
              name: e.target.value,
            })
          }
        }
        >
          <i className="fas fa-search"></i>
        </TextField>
      </div>
      
      <div className="col mr-2  ">
        <SelectField
          hasLabel={false}
          options={active}
          placeholder={translate.status}
          value={filter?.status}
          onChange={(e) =>{
            setfilter({
              ...filter,
              status: e,
            })
          }
        }
        />
      </div>

      <div className="col  pt-1" >
        <button className="btn btn-primary px-5 py-2 font-weight-bold">
          {translate.search}
        </button>
        <button className="btn text-muted font-weight-bold " onClick={()=>filterEmpty(filter)}>
          {" "}
           <i className="fas fa-retweet"></i>{translate.reset}
        </button>
      </div>
      
    </div>


   
</>
);
}
