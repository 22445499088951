import React from 'react'
import { useHistory } from 'react-router';
import Locale from '../../../../translations';


function TravelerProgress({employee}) {
    let history = useHistory();
    const { translate } = Locale;

    
  //console.log("traveler",employee)

 
    return (
      <>
      <tr>
         <td>{employee.reservation_no}</td> 
        <td>{employee.test_type}</td>
        <td>
        {employee?.traveller_name?.ar} <br/>
        {employee?.traveller_name?.en}
        </td>
        <td> {employee.reservation_type}</td>
        <td>
          <div className="d-flex flex-column">
            <span>{(employee.check_date)?.slice(0,10)} </span>
            <span>{(employee.check_date)?.slice(11,16)}</span>
          </div>
        </td>
        {/* <td>2</td>
        <td>950 ج.م</td> */}
        <td>{employee.operationOrder_employee} </td>
        {/* <td>{employee.remain_cost}</td> */}
        <td>
        <div className="d-flex flex-column">
            <span>{(employee.check_date).slice(0,10)}  </span>
            <span>{(employee.check_date).slice(11,16)}</span>
          </div>
        </td>

        <td>
        <div className="d-flex flex-column">
            <span>{(employee.operationOrder_created_at).slice(0,10)} </span>
            <span>{(employee.operationOrder_created_at).slice(11,16)}</span>
          </div>
        </td>
        <td>
          <button className="text-primary btn-link font-weight-bold mr-3"  onClick={() => {
            history.push(`/CustomersList/view-progress/result/${employee.id}`);
          }}><i class="fas fa-eye" style={{fontSize:"16px"}}></i> {translate.view}</button>

          <button className="text-primary btn-link font-weight-bold"  onClick={() => {
            history.push(`/CustomersList/view-progress/${employee.id}`);
          }}><i class="fas fa-share" style={{fontSize:"16px"}}></i>  {translate.enterResult}</button>

        </td>
      </tr>





 
</>
    );
}

export default TravelerProgress;
