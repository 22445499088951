import React, { useEffect, useState } from "react";
import SelectField from "../../../components/shared/SelectField";
import TextField from "../../../components/shared/textField";
import validate from "../../../helpers/validate";
import { companiesDrop } from "../../../services/admin";
import Locale from "../../../translations";
//  const [errorsServices, setErrorsServices] = useState({});

export default function AddService({ company, setCompany,index ,deleteFn,data ,setHandleFormServices,checkTypes,reservationTypes,checkGradeTypes,comission }) {
  const { translate } = Locale;



  const handleChange = (e) => {
      let serv=company?.services
    
    if ("target" in e) {
      const value = e.target.value;
      const name = e.target.name;
      
      serv[index]={...serv[index],[name]: value}
      setCompany({ ...company,services: serv });
    } else {
      const value = e.value;
      const name = e.name;
      serv[index]={...serv[index],[name]: value}
      setCompany({ ...company, services:serv });
    }
  };
  const commissionOptions = [
    { value: "fixed", label: "ثابتة" },
    { value: "percentage", label: "مئوية" },
  ];



 const   handlyChenageinArray =(name ,value)=>{
  let serv=company.services
  serv[index]={...serv[index],[name]: value}
  setCompany({ ...company, services:serv });
  //console.log("setCompany=>",company)
 }

const [inputs, setInputs] = useState({})
const [errors, setErrors] = useState({});

 const checkFormErrors = () => {
  setErrors({
    ...errors,
    ...validate(
      {
        name: "check_id",
        value: inputs.check_id,
      },
      {
        required: true,
      }
    ),
    ...validate(
      {
        name: "time",
        value: inputs.time,
      },
      {
        required: true,
      }
    ),
    ...validate(
      {
        name: "type_id",
        value: inputs.type_id,
      },
      {
        required: true,
      }
    ),
    ...validate(
      {
        name: "safa_comission",
        value: inputs.safa_comission,
      },
      {
        required: true,
        
      }
    ),
    ...validate(
      {
        name: "comission_type",
        value: inputs.comission_type,
      },
      {
        required: true,
        
      }
    ),
    ...validate(
      {
        name: "price",
        value: inputs.price,
      },
      {
        required: true,
        
      }
    ),
    ...validate(
      {
        name: "check_grade_id",
        value: inputs.check_grade_id,
      },
      {
        required: true,
      }
    ),
    ...validate(
      {
        name: "debit_comission",
        value: inputs.debit_comission,
      },
      {
        required: true,
      }
    ),...validate(
      {
        name: "credit_comission",
        value: inputs.credit_comission,
      },
      {
        required: true,
      }
    )
  });
};
const [isErrorLoaded, setIsErrorLoaded] = useState(false);

const handleForm = async () => {
  checkFormErrors();
  if (!isErrorLoaded) {
    setIsErrorLoaded(true);
  } else {
    setIsErrorLoaded(false);
  }
};

/**Api */


  return (
    <>
      <div className=" add-settings container bg-white m-3">
        <div className=" m-auto d-flex justify-content-center" style={{ width: "80%" }}>
          <div className="row py-3 " style={{ background: "#EDF2F7" }}>
            <div className="col-3 ">
              <SelectField
                onFocus={true}
                hasLabel={true}
                label={translate.service}
                placeholder={translate.service}
                options={checkTypes}
                name="check_id"
                value={data?.check_id}      
                onChange={(e) => {
                  handlyChenageinArray('check_id',e);
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "check_id", value: e },
                      {
                        required: true,
                      }
                    ),
                  });
                }}

                color={errors?.check_id?.required ? "danger" : ""}
                errors={errors?.check_id}
              />
            </div>

            <div className="col-3 ">
              <SelectField
                onFocus={true}
                hasLabel={true}
                label={translate.serviceType}
                placeholder={translate.serviceType}
                options={reservationTypes}
                name={"type_id"}
                value={data?.type_id}
                onChange={(e) => {
                  handlyChenageinArray('type_id',e)

                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "type_id", value: e },
                      {
                        required: true,
                      }
                    ),
                  });

                }}

                color={errors?.type_id?.required ? "danger" : ""}
                errors={errors?.type_id}
              />
            </div>

            <div className="col-3 ">
              <SelectField
                onFocus={true}
                hasLabel={true}
                label={translate.ServiceGrade}
                placeholder={translate.ServiceGrade}
                options={checkGradeTypes}
                name={"check_grade_id"}
                value={data?.check_grade_id}
                onChange={(e) => {
                  handlyChenageinArray('check_grade_id',e);
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "check_grade_id", value: e },
                      {
                        required: true,
                      }
                    ),
                  });
                }}

                color={errors?.check_grade_id?.required ? "danger" : ""}
                errors={errors?.check_grade_id}
              />
            </div>

            <div className="col-3 ">
              <TextField
                hasLabel={true}
                label={translate.ResultTime}
                placeholder={translate.ResultTime}
                name="time"
                value={data?.time}
                onChange={(e) => {
                  handlyChenageinArray('time',e.target.value)
        
                  setInputs({
                    ...inputs,
                    time: e.target.value,
                  });

                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "time", value: e.target.value },
                      {
                        required: true,
                      }
                    ),
                  });
                 }}
                 color={errors?.time?.required ? "danger" : ""}
                 errors={errors?.time}
              >
                <span style={{fontSize:"12px",marginTop:"20px",verticalAlign:"bottom"}}>{translate.hour}</span>
              </TextField>
            </div>

            {/* ******************************* */}

            <div className="col-3  mt-3">
              <TextField
                hasLabel={true}
                label={translate.sevicePrice}
                placeholder={translate.sevicePrice}
                name={"price"}
                value={data?.price}
                onChange={(e) => {
                  handlyChenageinArray('price',e.target.value)
                  setInputs({
                    ...inputs,
                    price: e.target.value,
                  });

                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "price", value: e.target.value },
                      {
                        required: true,
                      }
                    ),
                  });
                 
                }}

                color={errors?.price?.required ? "danger" : ""}
                errors={errors?.price}
              >
                <span style={{fontSize:"12px",marginTop:"20px",verticalAlign:"bottom"}}>ج.م</span>
              </TextField>
            </div>
            <div className="col-3  mt-3 ">
              <SelectField
                onFocus={true}
                hasLabel={true}
                label={translate.commission}
                placeholder={translate.commission}
                options={commissionOptions}
                // options={comission}
                name="comission_type"
                value={data?.comission_type}
                onChange={(e) => {
                  handlyChenageinArray('comission_type',e);
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "comission_type", value: e },
                      {
                        required: true,
                      }
                    ),
                  });
                }}
                color={errors?.comission_type?.required ? "danger" : ""}
                errors={errors?.comission_type}
              />
            </div>
            <div className="col-3  mt-3">
              <TextField
                hasLabel={true}
                label={translate.commissionValue}
                placeholder={translate.commission}
                name="safa_comission"
                value={data?.safa_comission}
                onChange={(e) => {
                  handlyChenageinArray('safa_comission',e.target.value)
                  setInputs({
                    ...inputs,
                    safa_comission: e.target.value,
                  });

                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "safa_comission", value: e.target.value },
                      {
                        required: true,
                      }
                    ),
                  });
                 }}

                 color={errors?.safa_comission?.required ? "danger" : ""}
                 errors={errors?.safa_comission}
              >
              <span style={{fontSize:"12px",marginTop:"20px",verticalAlign:"bottom"}}>ج.م</span>

              </TextField>
            </div>

            <div className="col-3  mt-3">
              <TextField
                hasLabel={true}
                type={"number"}
                label={"عمولة الدفع من المحفطة"}
                placeholder={translate.debit_comission}
                name="safa_comission"
                value={data?.debit_comission}
                onChange={(e) => {
                  handlyChenageinArray('debit_comission',e.target.value)
                  setInputs({
                    ...inputs,
                    debit_comission: e.target.value,
                  });

                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "debit_comission", value: e.target.value },
                      {
                        required: true,
                      }
                    ),
                  });
                 }}

                 color={errors?.debit_comission?.required ? "danger" : ""}
                 errors={errors?.debit_comission}
              >
              <span style={{fontSize:"12px",marginTop:"20px",verticalAlign:"bottom"}}>ج.م</span>

              </TextField>
            </div>
            
            <div className="col-3  mt-3">
              <TextField
                  type={"number"}
                hasLabel={true}
                label={"عمولة الدفع من رصيد صفا"}
                placeholder={translate.credit_comission}
                name="credit_comission"
                value={data?.credit_comission}
                onChange={(e) => {
                  handlyChenageinArray('credit_comission',e.target.value)
                  setInputs({
                    ...inputs,
                    credit_comission: e.target.value,
                  });

                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "credit_comission", value: e.target.value },
                      {
                        required: true,
                      }
                    ),
                  });
                 }}

                 color={errors?.credit_comission?.required ? "danger" : ""}
                 errors={errors?.credit_comission}
              >
              <span style={{fontSize:"12px",marginTop:"20px",verticalAlign:"bottom"}}>ج.م</span>

              </TextField>
            </div>

          {/* <button className=" btn " onClick={
                handleForm
                }>
            {translate.shouldBeValid}
          </button> */}


          </div>

          <button
            className="btn text-danger btn-lg my-3 mx-4"
            onClick={() => deleteFn(index)}
          >
            <i className="fas fa-trash-alt"></i> 
           
          </button> 
          
        </div>
      </div>
    </>
  );
}
