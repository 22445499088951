import React, { useEffect, useState } from "react";
//import Locale from "../../../translations";
import { useHistory, useParams } from 'react-router';
import { viewCompany } from "../../../../services/admin";
import Locale from "../../../../translations";
import '../style.scss';

export default function PaymentCost() {
  const { translate } = Locale;
  let history = useHistory();

//listTravellersById
const [company, setCompany] = useState([]);
let { id } = useParams();
 useEffect(() => {
  async function fetchData() {
    const res = await viewCompany(id);
    setCompany(res?.data?.data);
  }
  fetchData();
  
}, []);


  return (
    <>
    <div className="container-fluid p-0 " >
      <header className=" list_header border py-3 px-2 d-flex justify-content-between align-items-baseline my-4">
        <h3 className="text-muted font-weight-bold">
       {translate.MedicalServicesCompanyName}     </h3>
      </header>

      <div className="payment-cost px-5 py-4" >
        {/* *************************************************************************/}
        

        <div className="boxs d-flex justify-content-between align-items-center">
          <div className="box">
            <div className="layout text-center">
              <h3 className=" font-weight-bold">إجمالي الطلبات </h3>
              <h4 className="text-primary font-weight-bold">120</h4>
              <p className=" font-weight-bold">طلب خدمة طبية</p>
            </div>
          </div>

          <div className="box">
            <div className="layout text-center">
              <h3 className=" font-weight-bold">إجمالي الطلبات </h3>
              <h4 className="text-primary font-weight-bold">183,550</h4>
              <p className=" font-weight-bold">طلب خدمة طبية</p>
            </div>
          </div>

          <div className="box">
            <div className="layout text-center">
              <h3 className=" font-weight-bold">إجمالي الطلبات </h3>
              <h4 className="text-primary font-weight-bold">13,550</h4>
              <p className=" font-weight-bold">طلب خدمة طبية</p>
            </div>
          </div>

          <div className="box">
            <div className="layout text-center">
              <h3 className=" font-weight-bold">إجمالي الطلبات </h3>
              <h4 className="text-primary font-weight-bold">130,550</h4>
              <p className=" font-weight-bold">طلب خدمة طبية</p>
            </div>
          </div>

          <div className="box">
            <div className="layout text-center">
              <h3 className=" font-weight-bold">إجمالي الطلبات </h3>
              <h4 className="text-danger font-weight-bold">130,550</h4>
              <p className=" font-weight-bold">طلب خدمة طبية</p>
            </div>
          </div>

        </div>

        
      </div>




      
    </div>


   </>
  );
}
