import React from "react";
import Locale from "../../../translations";

export default function UserNumber(props) {
  const { translate } = Locale;
  const userCount = props.users? props.users.length : 0;

  const activationUsers = props.users?.filter((user) => {
    return user.status === "active";
  }).length;

  const inactivateUsers = props.users?.filter((user) => {
    return user.status === "inactive";
  }).length;

  return (
    <div className="container mt-5 p-0">
      <div className="d-flex">
        <div className="box font-weight-bold">
        <span style={{fontSize:"16px "}}>{userCount} <i class="fas fa-circle"></i></span> {translate.userCount}
        </div>

        <div className="box mx-5 font-weight-bold">
          <span className="text-success ">
            {" "}
            <span style={{fontSize:"16px "}}>{activationUsers} <i class="fas fa-circle"></i></span>
          </span>{" "}
          {translate.activationUsers}
        </div>

        <div className="box font-weight-bold">
          <span className="text-danger">
            <span style={{fontSize:"16px "}}>{inactivateUsers} <i class="fas fa-circle"></i> </span>
          </span>
          {translate.inactivateUsers}
        </div>
      </div>
    </div>
  );
}
