import React from 'react'
import { useHistory, useParams } from 'react-router';
import Locale from '../../../../translations';


function TravelerProgress({allData}) {
    let history = useHistory();
    let {id}=useParams()
    const { translate } = Locale;

    
  console.log("allDataemployeeemployee",allData)

 
    return (
      <>
      <tr>
        <td>{allData?.reservation_no}</td>
        <td>{allData?.test_type}</td>
        <td>
        {allData?.traveller_name} <br/>
        
        </td>
        <td> {allData?.company_name}</td>
        <td>
          {allData?.reservation_type} 
        </td>
        <td>
          <div className="d-flex flex-column">
            <span>{(allData?.check_date)?.slice(0,10)} </span>
            <span>{(allData?.check_date).slice(11,16)}</span>
          </div>
        </td>
        <td>{allData?.operationOrder_employee}</td>
        <td> 
          <div className="d-flex flex-column">
            <span>{(allData?.request_date)?.slice(0,10)} </span>
            <span>{(allData?.request_date).slice(11,16)}</span>
          </div>
        </td>

        <td>
          <div className="d-flex flex-column">
            <span>{(allData?.operationOrder_created_at)?.slice(0,10)} </span>
            <span>{(allData?.operationOrder_created_at).slice(11,16)}</span>
          </div>
        </td>
        <td>{allData?.amount} {translate.Pound}</td>
      

        <td className="text-orange">{allData?.comission} {translate.Pound}</td>
       
        <td className="text-orange">{allData?.safa_comission} {translate.Pound}</td>
        <td>
          <button className="text-primary mr-3" onClick={() => {
            history.push(`/admin/ordersList/progress/view/${id}/order/${allData.id}`);
          }}><i class="fas fa-chevron-left"></i> </button>

         
        </td>
      </tr> 





 
</>
    );
}

export default TravelerProgress;
