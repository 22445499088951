import React, { useEffect, useState } from "react";
import Locale from "../../../translations";
import { useHistory, useParams } from 'react-router';
import TextField from "../../../components/shared/textField";
import { viewCompany } from "../../../services/admin";
//import { listTravellersById } from "../../../services/yarvel";
export default function ViewComanyMedical() {
  const { translate } = Locale;
  let history = useHistory();

//listTravellersById
const [company, setCompany] = useState([]);
let { id } = useParams();
 useEffect(() => {
  async function fetchData() {
    const res = await viewCompany(id);
    setCompany(res?.data?.data);
  }
  fetchData();
  
}, []);


  return (
    <>
    <div className="container p-0">
      <header className=" list_header border py-3 px-2 d-flex justify-content-between align-items-baseline mt-4">
        <h3 className="text-muted font-weight-bold"> {translate.MedicalServicesCompanyName}  </h3>
      </header>

      <div className="bg-white px-5 py-4">
        {/* *************************************************************************/}
        
        <div className=" m-auto" style={{width:"85%"}}>
          <div className="row">
            <div className="col-6">
             <div className="row">
               <div className="col-4 font-weight-bold">
               {translate.nameOperation}
               </div>
               <div className="col-4">
               <h5>{company?.user?.name}</h5>
               </div>
             </div>

             <div className="row my-3">
               <div className="col-4 font-weight-bold">
               {translate.email}
               </div>
               <div className="col-4">
               <h5>{company?.user?.email}</h5>
               </div>
             </div>

             <div className="row my-2">
               <div className="col-4 font-weight-bold">
               {translate.address}
               </div>
               <div className="col-4">
               <h5>{company?.address}</h5>
               </div>
             </div>

            </div>

            <div className="col-6">
             <div className="row">
               <div className="col-4 font-weight-bold">
               {translate.country}
               </div>
               <div className="col-4">
               <h5>{company?.country?.names?.ar}</h5>
               </div>
             </div>

             <div className="row my-3">
               <div className="col-4 font-weight-bold">
               {translate.city}
               </div>
               <div className="col-4">
               <h5>{company?.city?.names?.ar}</h5>
               </div>
             </div>

             <div className="row my-2">
               <div className="col-4 font-weight-bold">
               {translate.phoneNumber}
               </div>
               <div className="col-4">
               <h5>{company?.phone_number}</h5>
               </div>
             </div>

            </div>
          </div>

        </div>








        {/* <div className="w-75 m-auto">
        <div className=" py-3 p-2 row">
            <div className="col-4">
            <TextField
											type="text"
                      hasLabel={true}
											label={translate.companyName}
											//placeholder={translate.name}
											name="companyName"
											value={company?.name}
                      disabled
										/>
            </div>
            <div className="col-4">
            <TextField
											type="text"
                      hasLabel={true}
											label={translate.country}
											//placeholder={translate.name}
											name="name"
											value={company?.country?.names?.ar}
                      disabled
										/>
            </div>
            <div className="col-4">
            <TextField
											type="text"
                      hasLabel={true}
											label={translate.city}
											//placeholder={translate.name}
											name="name"
											value={company?.city?.names?.ar}
                      disabled
										/>
            </div>

          
        </div>

        <div className=" py-3 p-2 row">
            <div className="col-4">
            <TextField
											type="text"
                      hasLabel={true}
											label={translate.nameOperation}
											//placeholder={translate.name}
											name="name"
											value={company?.user?.name}
                      disabled
										/>
            </div>
            <div className="col-4">
            <TextField
											type="text"
                      hasLabel={true}
											label={translate.email}
											//placeholder={translate.name}
											name="name"
											value={company?.user?.email}
                      disabled
										/>
            </div>
            <div className="col-4">
            <TextField
											type="text"
                      hasLabel={true}
											label={translate.phoneNumber}
											//placeholder={translate.name}
											name="name"
											value={company?.user?.phone_number}
                      disabled
										/>
            </div>

          
        </div>
        <div className=" py-3 p-2 row">
            <div className="col-12">
            <TextField
											type="text"
                      hasLabel={true}
											label={translate.address}
											
											name="name"
											value={company?.address}
                      disabled
										/>
            </div>
            
           

          
        </div>

        </div> */}

        
      </div>




      
    </div>

    <div className="container p-0">
      <header className=" list_header border py-3 px-2 d-flex justify-content-between align-items-baseline mt-4">
        <h3 className="text-muted font-weight-bold">
        {translate.services}       </h3>
      </header>

      <div className="bg-white px-5 py-4">
        {/* *************************************************************************/}
        
        <div className="w-100 m-auto">
        
        


          { company?.services && company?.services?.map((service,index)=>(
 <div className=" instructions my-4 py-3 p-2  row" key={index}>

<div className=" m-auto" style={{width:"85%"}}>
          <div className="row">
            <div className="col-6">
             <div className="row">
               <div className="col-4 font-weight-bold">
               {translate.service}
               </div>
               <div className="col-4">
               <h5>{[service.check]}</h5>
               </div>
             </div>

             <div className="row my-3">
               <div className="col-4 font-weight-bold">
               {translate.serviceType}
               </div>
               <div className="col-4">
               <h5>{service.type}</h5>
               </div>
             </div>

             <div className="row my-2">
               <div className="col-4 font-weight-bold">
               {translate.serviceDegree}
               </div>
               <div className="col-4">
               <h5>{service.grade}</h5>
               </div>
             </div>

             <div className="row my-2">
               <div className="col-4 font-weight-bold">
               نوع العمولة
               </div>
               <div className="col-4">
               <h5>ثابتة</h5>
               </div>
             </div>

             <div className="row my-2">
               <div className="col-4 font-weight-bold">
               العمولة من رصيد صفا
               </div>
               <div className="col-4">
               <h5>{service.debit_comission} {translate.pound}</h5>
               </div>
             </div>

            </div>

            <div className="col-6">
             <div className="row">
               <div className="col-4 font-weight-bold">
               {translate.Result}
               </div>
               <div className="col-4">
               <h5>{service.time} {translate.hour}</h5> 
               </div>
             </div>

             <div className="row my-3">
               <div className="col-4 font-weight-bold">
               {translate.sevicePrice}
               </div>
               <div className="col-4">
               <h5>{service.price} {translate.pound} </h5>
               </div>
             </div>

             <div className="row my-2">
               <div className="col-4 font-weight-bold">
               {translate.commission}
               </div>
               <div className="col-4">
               <h5>{service.safa_comission} {translate.pound}</h5>
               </div>
             </div>
             <div className="row my-2">
               <div className="col-4 font-weight-bold">
              العمولة من المحفظة
               </div>
               <div className="col-4">
               <h5>{service.credit_comission} {translate.pound}</h5>
               </div>
             </div>

            

            </div>
          </div>

        </div>




 {/* <div className="col-3">
 <TextField
           type="text"
           hasLabel={true}
           label={translate.addUser}
           //placeholder={translate.name}
           name="name"
           value={service.check}
           disabled
         />
 </div>
 <div className="col-3">
 <TextField
           type="text"
           hasLabel={true}
           label={translate.serviceType}
           //placeholder={translate.name}
           name="name"
           value={service.type}
           disabled
         />
 </div>
 <div className="col-3">
 <TextField
           type="text"
           hasLabel={true}
           label={translate.ServiceGrade}
           //placeholder={translate.name}
           name="name"
           value={service.grade}
           disabled
         />
 </div>
 <div className="col-3">
 <TextField
           type="text"
           hasLabel={true}
           label={translate.ResultTime}
           //placeholder={translate.name}
           name="name"
           value={service.time +"ساعة"}
           disabled
         />
 </div>

 <div className="col-3 my-3">
 <TextField
           type="text"
           hasLabel={true}
           label={translate.sevicePrice}
           //placeholder={translate.name}
           name="name"
           value={service.price}
           disabled
         />
 </div>
 <div className="col-3 my-3">
 <TextField
           type="text"
           hasLabel={true}
           label={translate.commission}
           //placeholder={translate.name}
           name="name"
           value={service.comission_type}
           disabled
         />
 </div>
 <div className="col-3 my-3">
 <TextField
           type="text"
           hasLabel={true}
           label={translate.commission}
           //placeholder={translate.name}
           name="name"
           value={service.safa_comission}
           disabled
         />
         
 </div> */}



</div>


          )
          )}
           



           <div className="text-center text-grey w-25 m-auto">
        {company?.services?.length > 0 ? (
												""
											) : (
												<tr>
													<td colSpan="10" className="text-center my-3">
														<div className="product-build__product-no-data">
															<i className="fas fa-info-circle fa-lg"></i>{" "}
															<h4>{translate.noResult}</h4>
														</div>
													</td>
												</tr>
											)}
        </div>



       
        

        </div>

        
      </div>




      <button className=" btn btn-secondary px-5 mt-2" onClick={() => {
              history.push("/admin/ComanyMedical");
              }}> {translate.back}</button>
    </div>
   </>
  );
}
