import React, { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";

//******************Start Check Me********************************


import CustomersList from "./modules/checkMe/travelers/CustomersList";
import Progress from "./modules/checkMe/travelers/CustomersList/progress";
import Checked from "./modules/checkMe/travelers/CustomersList/checked";
import View from "./modules/checkMe/travelers/CustomersList/view";
import Payments from "./modules/checkMe/Payments"
import Services from "./modules/checkMe/Services";
import Setting from "./modules/checkMe/setting";
import ViewProgress from "./modules/checkMe/travelers/CustomersList/viewProgress";
import ViewCheched from "./modules/checkMe/travelers/CustomersList/viewChecked";
import DashboardCompanies from "./modules/checkMe/dashboard/DashboardCompanies";
import ViewResultProgress from "./modules/checkMe/travelers/CustomersList/viewResultProgress";
import ViewOPeration from "./modules/checkMe/travelers/CustomersList/viewOperation";
import Login from "./modules/checkMe/auth/auth";
import { AuthRoute } from "./components/AuthRoute";
import CheckRouter from "./components/CheckRouter";


// *************************Import Admin************************
import AdminRouter from "./components/AdminRouter";
//AddCompany
import LoginAdmin from "./modules/admin/auth/auth";
import DashboardCompaniesAdmin from "./modules/admin/dashboard/DashboardCompanies";
import ComanyMedicalList from "./modules/admin/ComanyMedicalList";
import ViewComanyMedical from "./modules/admin/ComanyMedicalList/ViewComanyMedical";
import EditCompany from "./modules/admin/ComanyMedicalList/editCompany";
import AddCompany from "./modules/admin/ComanyMedicalList/addCompany";
import UsersAdmin from "./modules/admin/ComanyMedicalList/user/users";
import OrdersList from "./modules/admin/ComanyMedicalList/OrdersList";
import OrdersProgress from "./modules/admin/ComanyMedicalList/OrdersList/progress";
import OrdersChecked from "./modules/admin/ComanyMedicalList/OrdersList/checked";
import ViewIncoming from "./modules/admin/ComanyMedicalList/OrdersList/view";
import ViewProgressOrders from "./modules/admin/ComanyMedicalList/OrdersList/viewProgress";
import ViewCheckedOrders from "./modules/admin/ComanyMedicalList/OrdersList/viewChecked";
import payment from "./modules/admin/ComanyMedicalList/payment/payment";
// import AddUser from "./modules/admin/ComanyMedicalList/user/addUser";
// import EditUser from "./modules/admin/ComanyMedicalList/user/editUser";

import Users from "./modules/checkMe/WorkTeam/index";
import AddUser from "./modules/checkMe/WorkTeam/add";
import EditUser from "./modules/checkMe/WorkTeam/edit";
import Groups from "./modules/checkMe/WorkTeam/groups";
import AddGroup from "./modules/checkMe/WorkTeam/addGroup";
import EditGroup from "./modules/checkMe/WorkTeam/editGroup";


import WorkTeamAdmin from "./modules/admin/WorkTeam/index";
import AddAdminUser from "./modules/admin/WorkTeam/add";
import EditAdminUser from "./modules/admin/WorkTeam/edit";
import AdminGroups from "./modules/admin/WorkTeam/groups";
import AddAdminGroup from "./modules/admin/WorkTeam/addGroup";
import EditAdminGroup from "./modules/admin/WorkTeam/editGroup";

const Routes = (
  <Suspense fallback={<div>Loading...</div>}>
    <Switch>
  

       {/*****************************Start Check Me***************************************************** */}
      <AuthRoute exact path="/login" component={Login} />

      <CheckRouter
        exact
        path="/CustomersList/dashboard/DashboardCompanies"
        component={DashboardCompanies}
      />
      
       {/******************Start Travelers******************* */}
      <CheckRouter
        exact
        path="/CustomersList/orders"
        component={CustomersList}
      />

      <CheckRouter
        exact
        path="/CustomersList/progress"
        component={Progress}
      />
      <CheckRouter
        exact
        path="/CustomersList/checked"
        component={Checked}
      />

      <CheckRouter
      exact 
      path="/CustomersList/view-result/:id" 
      component={View} 
      />

      <CheckRouter
      exact 
      path="/CustomersList/view-operation/:id" 
      component={ViewOPeration} 
      />
      <CheckRouter
      exact 
      path="/CustomersList/view-progress/:id" 
      component={ViewProgress} 
      />
      <CheckRouter
      exact 
      path="/CustomersList/view-progress/result/:id" 
      component={ViewResultProgress} 
      />

      <CheckRouter
      exact 
      path="/CustomersList/view-Cheched/:id" 
      component={ViewCheched} 
      />


     {/******************Start WorkTeam******************* */}
       {/* workTeam */}
       <CheckRouter exact path="/team-management/users" component={Users} />
      <CheckRouter
        exact
        path="/team-management/users/add"
        component={AddUser}
      />
      <CheckRouter
        exact
        path="/team-management/users/edit/:user_id"
        component={EditUser}
      />
      <CheckRouter exact path="/team-management/groups" component={Groups} />
      <CheckRouter
        exact
        path="/team-management/groups/add"
        component={AddGroup}
      />
      <CheckRouter
        exact
        path="/team-management/groups/edit/:group_id"
        component={EditGroup}
      />

      <CheckRouter
        exact
        path="/Seting"
        component={Setting}
      />

      <CheckRouter
        exact
        path="/Payments"
        component={Payments}
      />
      
      <CheckRouter
        exact
        path="/servics"
        component={Services}
      />
      <CheckRouter exact path="/Company/view-result" component={View} />

      {/* ***********************End Check Me**************************************** */}
      {/* ***********************Start Admin***************************************** */}



      <AuthRoute exact path="/admin/login" component={LoginAdmin} />  {/* AuthRoute */}

      <AdminRouter
        exact
        path="/admin/dashboard/DashboardCompanies"
        component={DashboardCompaniesAdmin}
      />
     <AdminRouter
        exact
        path="/admin/ComanyMedical"
        component={ComanyMedicalList}
      />
      <AdminRouter
        exact
        path="/admin/ComanyMedical-/:id"
        component={ViewComanyMedical}
      />

       {/* workTeam */}
       <AdminRouter exact path="/admin/team-management/users" component={WorkTeamAdmin} />
      <AdminRouter
        exact
        path="/admin/team-management/users/add"
        component={AddAdminUser}
      />
      <AdminRouter
        exact
        path="/admin/team-management/users/edit/:user_id"
        component={EditAdminUser}
      />
      <AdminRouter exact path="/admin/team-management/groups" component={AdminGroups} />
      <AdminRouter
        exact
        path="/admin/team-management/groups/add"
        component={AddAdminGroup}
      />
      <AdminRouter
        exact
        path="/admin/team-management/groups/edit/:group_id"
        component={EditAdminGroup}
      />

{/* *********Start Admin Orders */}
      <AdminRouter
        exact
        path="/admin/ordersList/incoming/:id"
        component={OrdersList}
      />


      <AdminRouter
        exact
        path="/admin/ordersList/progress/:id"
        component={OrdersProgress}
      />
      
      <AdminRouter
        exact
        path="/admin/ordersList/checked/:id"
        component={OrdersChecked}
      />

      <AdminRouter
        exact
        path="/admin/ordersList/incoming/view/:id/order/:index"
        component={ViewIncoming}
      />

      <AdminRouter
        exact
        path="/admin/ordersList/progress/view/:id/order/:index"
        component={ViewProgressOrders}
      />


    <AdminRouter
        exact
        path="/admin/ordersList/checked/view/:id/order/:index"
        component={ViewCheckedOrders}
      />
{/* *********End Admin Orders */}
      <AdminRouter
        exact
        path="/admin/payment/:id"
        component={payment}
      />


      <AdminRouter
        exact
        path="/admin/users/:id"
        component={UsersAdmin}
      />
      {/* <AdminRouter
        exact
        path="/admin/ComanyMedical/add-user"
        component={AddUser}
      /> */}

      {/* <AdminRouter
        exact
        path="/admin/ComanyMedical/edit-user/:id"
        component={EditUser}
      /> */}

        <AdminRouter
        exact
        path="/admin/ComanyMedical-edit/:id"
        component={EditCompany}
      />

    <AdminRouter
        exact
        path="/admin/ComanyMedical/AddCompany"
        component={AddCompany}
      />
      <AuthRoute exact path="/" component={Login} />


    </Switch>
  </Suspense>
);
export default Routes;
