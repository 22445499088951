import axios from "axios";
import { cleanEmpty } from "./general";
const baseURL = process.env.REACT_APP_API_URL;
const [protocol, domain] = baseURL.split("//");

const host = window.location.host;
const subdomain = host != null ? host.split(".")[0] : null;
//debugger
//export const SafarnaURL = `https://${domain}/api`;
console.log("subdomainsubdomainsubdomain",subdomain)
let tenantUrl = `https://${domain}/api/tenant`;
if (subdomain != null) {
  //tenantUrl = `http://${domain}/api/tenant`;  
  tenantUrl = `http://${subdomain}.${domain}/api/tenant`;
}
//check-me-back.
export const CheckURL = tenantUrl;



export const singin = async (data) => {
  return await axios
    .post(`${CheckURL}/auth/login`, data)
    .then((res) => res)
    .catch((err) => err.response);
};



///******************Traveller************************* */
export const listTravellers = async (filter) => {
		return await axios
		.get(`${CheckURL}/labs/travellers`)
		.then((res) => res)
		.catch((err) => err.response);

};


export const listTravellersFilter = async (filter) => {
	return await axios
	.get(`${CheckURL}/labs/travellers`,{
		params: cleanEmpty(filter)
	  })
	.then((res) => res)
	.catch((err) => err.response);

};




export const listEmployees = async () => {
	return await axios
		.get(`${CheckURL}/labs/travellers/employees`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listTravellersById = async (id) => {
	return await axios
		.get(`${CheckURL}/labs/travellers/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listPendingTravellers= async () => {
	return await axios
		.get(`${CheckURL}/labs/travellers/pending?name=&departure_date&check_date`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listPendingTravellersFilter= async (filter) => {
	return await axios
		.get(`${CheckURL}/labs/travellers/pending`,{
			params: cleanEmpty(filter)
		  })
		.then((res) => res)
		.catch((err) => err.response);
};

export const listDoneTravellers= async () => {
	return await axios
		.get(`${CheckURL}/labs/travellers/checked?name=&departure_date&check_date&result&result_date`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const listDoneTravellersFilter= async (filter) => {
	return await axios
		.get(`${CheckURL}/labs/travellers/checked`,{
			params: cleanEmpty(filter)
		  })
		.then((res) => res)
		.catch((err) => err.response);
};


export const createOperationOrder= async (data) => {
	return await axios
		.post(`${CheckURL}/labs/travellers/order`,data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const createResultProgress= async (id,data) => {
	return await axios
		.post(`${CheckURL}/labs/travellers/${id}`,data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const showDataSettings= async () => {
	return await axios
		.get(`${CheckURL}/labs/me`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editSettings = async (data) => {
	return await axios
		.put(`${CheckURL}/labs/me`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const serviceList= async () => {
	return await axios
		.get(`${CheckURL}/companies/services`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const editServices = async (id) => {
	return await axios
		.put(`${CheckURL}/companies/services/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

///{{tenant_domain}}/api/tenant/labs/dashboard
export const dashboardList= async () => {
	return await axios
		.get(`${CheckURL}/labs/dashboard`)
		.then((res) => res)
		.catch((err) => err.response);
};
///lookups/cities
export const cities= async () => {
	return await axios
		.get(`${CheckURL}/lookups/cities?country_id=20`)
		.then((res) => res)
		.catch((err) => err.response);
};
///lookups/areas
export const areas= async (id) => {
	return await axios
		.get(`${CheckURL}/lookups/areas?city_id=`+id)
		.then((res) => res)
		.catch((err) => err.response);
};