import React, { useEffect, useRef, useState } from "react";
import Locale from "../../../../translations";
import { useHistory, useParams } from 'react-router';
//import SelectField from "../../../components/shared/SelectField";
import { createResultProgress, listTravellersById } from "../../../../services/yarvel";
import TextField from "../../../../components/shared/textField";

export default function ViewResultProgress() {
  const { translate } = Locale;
  let history = useHistory();

  /********* */

//listTravellersById
const [employee, setEmployee] = useState([]);
let { id } = useParams();

useEffect(() => {
  async function fetchData() {
    const Employees = await listTravellersById(id);
    setEmployee(Employees?.data?.data);
    //console.log("000000 is ",Employees.data)
  }
  fetchData();


  
}, []);


  /********************** */
  const [selectedValue, setSelectedValue] = useState(" ");

  const optionsTest=[
    {value: 1, label: "negative"},
    {value: 2, label: "positive"}
  ] 

  const handleChange = e => {
    setSelectedValue(e.label);
    //console.log("e.value*****",e.label)
  }
 
  /**************** */



  /********Api */

  //createResultProgress

const [resultView, setResultView] = useState(" ")
  /********** */
  return (
    <div className="container p-0">
      <header className=" list_header border py-3 px-2 d-flex justify-content-between align-items-baseline mt-4">
        <h3 className="text-muted font-weight-bold">
        فحص PCR
        </h3>
      </header>

      <div className="bg-white px-5 py-4">
        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.orderData}
        </h5>
        <div className="instructions py-3 p-2 row">
        <p className="col-2 h5  py-1 font-weight-bold">
            {translate.check}
          </p>
          <p className="col-4 h5 py-1">{employee?.test_type}</p>
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.orderNumber}
          </p>
          <p className="col-4 h5 py-1">{employee?.reservation_no}</p>
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.typeCheck}
          </p>
          <p className="col-4 h5 py-1">{employee?.reservation_type}</p>

          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.timeRequest}
          </p>
          <p className="col-4 h5 py-1">{(employee?.request_date)?.slice(0,10)} | {(employee?.request_date)?.slice(11,16)}</p>


          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.serviceDegree}
          </p>
          <p className="col-4 h5 py-1">{employee?.grade}</p>

          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.DataOperation}
          </p>
          <p className="col-4 h5 py-1">{(employee?.operationOrder_created_at)?.slice(0,10)} | {(employee?.operationOrder_created_at)?.slice(11,16)}</p>


          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.ResultTimeResult}
          </p>
          <p className="col-4 h5 py-1">{employee?.check_date_period} {translate.hour}</p>

          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.nameOperation}
          </p>
          <p className="col-4 h5 py-1">{employee?.operationOrder_employee} </p>
          
        </div>
        {/* <div className="instructions py-3 p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.orderNumber}
          </p>
          <p className="col-4 h5 py-1"> {employee?.reservation_no}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.ResultTimeResult}
          </p>
          <p className="col-4 h5 py-1">{employee?.check_date_period} ساعة</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.timeRequest}
          </p>
          <p className="col-4 h5 py-1">{(employee?.request_date)?.slice(0,10)} | {(employee?.request_date)?.slice(11,16)}</p>
        </div> */}


 {/* *************************************************************************/}
 {employee.test_type=='PCR' ? (
          <>
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.TripData}
        </h5>
        
        <div className="instructions py-3 p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.destnation}
          </p>
          <p className="col-4 h5 py-1">{employee?.trip?.destination?.names?.ar}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.departureDate}
          </p>
          <p className="col-4 h5 py-1">{employee?.trip?.arrival_date}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.Transporter}
          </p>
          <p className="col-4 h5 py-1">{employee?.trip?.transporter?.ar}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.departureDate}
          </p>
          <p className="col-4 h5 py-1">{employee?.trip?.departure_date}</p>
        </div>
        </>
        ) :""}
        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.CustomerData}
        </h5>

        <div className="instructions py-3 p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.nameArabic}
          </p>
          <p className="col-4 h5 py-1">{employee.traveller_name_ar}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.nationalId}
          </p>
          <p className="col-4 h5 py-1">{employee?.national_id}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.EnglishName}
          </p>
          <p className="col-4 h5 py-1">{employee.traveller_name_en}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.passportNumber}
          </p>
          <p className="col-4 h5 py-1">{employee.passport_number}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.Nationality}
          </p>
          <p className="col-4 h5 py-1">{employee.nationality}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.phoneNumber}
          </p>
          <p className="col-4 h5 py-1">{employee.phone}</p>
        </div>

       
        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.ExaminationData}
        </h5>

        <div className="instructions py-3 p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.examinationStatus}
          </p>
          <p className="col-4 h5 py-1">{employee.test_type}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.ServiceGrade}
          </p>
          <p className="col-4 h5 py-1">{employee.grade}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.Transporter}
          </p>
          <p className="col-4 h5 py-1">{employee.reservation_type}</p>
        </div>

        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.Cost}
        </h5>

        <div className="instructions py-3 p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.TotalCost}
          </p>
          <p className="col-4 h5 py-1">{employee?.amount} جنيه</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.Paid}
          </p>
          <p className="col-4 h5 py-1">{employee.amount} جنيه</p>
          

         
        </div>
        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.analysisResults}
        </h5>

        <div className="instructions py-3 p-2 ">
         
         






        <div className=" m-auto " style={{width:"40%"}}>
              <div className=" py-3 "  >
                <div >
                    
                    <TextField
                      onFocus={true}
                      hasLabel={true}
                      label={translate.analysisResults}
                      type={"text"}
                      placeholder={translate.noResult}
                      value={employee?.result}
                      className={employee?.result=='positive'?"text-danger font-weight-bold":'font-weight-bold text-success'}
                      />
          
                </div>


                <div className="box0 d-flex justify-content-between align-items-center bg-white w-100 my-3 p-3">

                  <div className="">
                    <p>رفع نتيجة الفحص <br/> * {(employee?.attachment)?.slice(0,50)} * </p>
                  </div>



                  <button className="btn btn-primary px-3"  >
                    
                    <input type="file" id="file"  style={{display: "none"}} />
                    {translate.selectFile}
                </button>


                       

                </div>

               
              {/* <button className="btn btn-success btn-lg my-3 w-100">{translate.sendResult} </button> */}

          </div>


</div>





        </div>







      </div>




      <button className=" btn btn-secondary px-5 my-4 font-weight-bold" onClick={() => {
              history.push("/CustomersList/progress");
              }}> {translate.back}</button>
    </div>

 
    
  );
}
