import { store } from "react-notifications-component";
import { editServices } from "../../../services/yarvel";
import Locale from "../../../translations";
export default function ServicesList({ service,setToggle}){
    let serviceBtn;
    const { translate } = Locale;

/***Api edit */
//editServices
const edit=async ()=>{
    const res = await editServices(service.id);
    if (res.status === 200) {
        setToggle(current=>!current)
          store.addNotification({
          title: "info!",
          message: translate.serviceApproved,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      }

}
/***Api edit */

    if(service.status===0){
        serviceBtn= <button className="btn btn-success font-weight-bold py-2 w-75" onClick={edit}>{translate.servicesEnsure}</button>
    }else{
        serviceBtn= <button className="btn text-success font-weight-bold py-2 w-75"><i class="fas fa-check "></i> {translate.serviceAdded}</button>
    }
    
    return (
        <>
            <div className="bg-white  px-5 py-3 ">
                <div className="d-flex  m-auto p-3 instructions" style={{width:"80%"}}>
                    <div className="w-100 row">
                    <p className="col-6 h5  py-2 font-weight-bold">
                            {translate.service}
                        </p>
                        <p className="col-6 h5 py-2">{service.check}</p>

                        <p className="col-6 h5 py-2 font-weight-bold">
                            {translate.serviceType}
                        </p>
                        <p className="col-6 h5 py-2">{service.type}</p>

                        <p className="col-6 h5 py-2 font-weight-bold">
                            {translate.serviceDegree}
                        </p>
                        <p className="col-6 h5 py-2">{service.grade}</p>
                    </div>

                    <div className="w-100 row mx-5">
                    <p className="col-6 h5  py-2 font-weight-bold">
                            {translate.ResultTime}
                        </p>
                        <p className="col-6 h5 py-2">{service.time} {translate.hour} </p>

                        <p className="col-6 h5 py-2 font-weight-bold">
                            {translate.sevicePrice}
                        </p>
                        <p className="col-6 h5 py-2">{service.price} {translate.pound}</p>

                        <p className="col-6 h5 py-2 font-weight-bold">
                            {translate.commission}
                        </p>
                        <p className="col-6 h5 py-2">{service.safa_comission} {translate.pound}</p>
                    </div>
                
                    <div className=" w-50 d-flex align-items-center" >
                       {serviceBtn}
                    </div>
                </div>
            </div>
        </>

    );

}