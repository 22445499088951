import React from "react";
import DatePicker from "../../../components/shared/DatePicker";
import SelectField from   "../../../components/shared/SelectField";
import TextField from "../../../components/shared/textField";
import Locale from "../../../translations";


export default function Filter() {
  const { translate } = Locale;
  return (
    <div className="row no-gutters list_header ">
      <div className="col-2 ">
        <TextField
          hasLabel={false}
          label={translate.search}
          type={"text"}
          placeholder={translate.search}
        >
          <i className="fas fa-search"></i>
        </TextField>
      </div>
      <div className="col-2 mx-4">
        <DatePicker hasLabel={false} placeholder={translate.date} />
      </div>

      

      <div className="col-2 ">
        <SelectField
          hasLabel={false}
          onFocus={true}
          label={translate.status}
          placeholder={translate.status}
        />
      </div>

      <div className="mx-2">
        <button className="btn btn-primary  text_filed px-5 py-2 font-weight-bold">
          {translate.search}
        </button>
        <button className="btn text-muted font-weight-bold ">
          {" "}
          <i className="fas fa-retweet"></i>{translate.reset} 
        </button>
      </div>
    </div>
  );
}
