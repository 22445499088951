import React, { useEffect, useState } from "react";
import Locale from "../../../../translations";
import { NavLink} from "react-router-dom";
import Filter from "./filter";
import ListTravelers from "./listTravelers";
//import { listTravellers } from "../../../services/yarvel";
//import { listTravellersFilter } from "../../../services/yarvel";
import moment from "moment";
import { listTravellersFilter } from "../../../../services/yarvel";
function CustomersList() {
  const { translate } = Locale;
 
  /**Filter  here */

  const [filter, setfilter] = useState({
    name:"",
    departure_date: "",
    check_date: "",
  });

  const [searching, setSearching] = useState(false)
  const [allData,setAllData]=useState([])
  const submit = () => {

    setSearching(!searching);
  }


  useEffect(() => {
    async function fetchListClients() {
      
      const list = await listTravellersFilter({
        ...filter,
        departure_date: filter.departure_date
          ? moment(filter.departure_date).format("YYYY-MM-DD")
          : "",
          check_date: filter.check_date
          ? moment(filter.check_date).format("YYYY-MM-DD")
          : "",
      });

setAllData(list?.data)
    }
    fetchListClients();
  }, [searching]);


 /**Filter here */







  return (
    <section>
      <div className="container p-0 my-4">
        

        <header className=" list_header border py-2 px-2 d-flex justify-content-between align-items-baseline">
          <h3 className="text-muted font-weight-bold">{translate.ComanyMedical}</h3>
          <div>
             <NavLink
                exact={true}
                to="/CustomersList/orders"
                activeClassName="is-active"
                className="tab-link  py-2 px-4 text-bold bg-white mr-3 bg-brwon rounded text-muted"
              >
                {translate.IncomingRequests}
              </NavLink>
              <NavLink
                exact={true}
                to="/CustomersList/progress"
                activeClassName="is-active"
                className=" tab-link py-2 px-4 text-bold bg-white mr-3  bg-brwon rounded text-muted"
              >
                {translate.InProgress}
              </NavLink>

              <NavLink
                exact={true}
                to="/CustomersList/checked"
                activeClassName="is-active"
                className=" tab-link py-2 px-4 text-bold bg-white  bg-brwon rounded text-muted"
              >
                {translate.AreChecked}
              </NavLink>
          </div>
        </header>
        <Filter submit={submit} filter={filter} setfilter={setfilter} />{/*submit={submit} filter={filter} setfilter={setfilter} */}
        <ListTravelers allData={allData} />
       
      </div>

     
    </section>
  );
}

export default CustomersList;
