import React from "react";
import logoImg from "../../assets/images/Image1.png";
import Locale from "../../translations";
import UserSetting from "../UsetSetting/UserSetting";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useCompanyDispatch } from "../../context/global";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
function HeaderCheckMe() {
  const { translate } = Locale;
  /** */
  const { pathname } = useLocation();
  const userType = localStorage.getItem("userType");/*localStorage.getItem("userType") */
  const dispatch = useCompanyDispatch();

  /*** */
  if (userType != null || undefined) {/*userType != null || undefined*/
    return (
      <>
       <div className="header-container ">
        <header className="header">

        </header>
        <div className="w-100 bg-white pt-2">
          <div className="container p-0 ">
            <div className="d-flex justify-content-between align-items-baseline">
              <figure className="logo">
                <img className="w-100" src={logoImg} alt="logo" />
              </figure>
              <div className="header-icons d-flex  justify-content-end" >
              <UncontrolledDropdown setActiveFromChild>
                <DropdownToggle tag="button" className="h5 text-primary d-inline" caret>
                <i class="fas fa-sign-out-alt"></i>

                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    tag="button"
                    onClick={() => {
                      dispatch({ type: "logout", payload: 'logout' });
                    }}
                  >
                    تسجيل خروج

                  </DropdownItem>
              
                </DropdownMenu>
              </UncontrolledDropdown>

                 {/* <UserSetting />  */}
                
              </div>
            </div>
          </div>
        </div>

        <nav className="header ">
          <div className="d-flex justify-content-between align-items-stretch  container p-0 ">
            <div className="d-flex  ">
              <NavLink
                exact={true}

                to="/CustomersList/dashboard/DashboardCompanies"
                activeClassName="is-active"
                className="mr-4 py-2 text-bold"
              >
                {translate.MainMenu}
              </NavLink>

              <NavLink
                exact={true}

                to="/CustomersList/orders"
                activeClassName="is-active"
                //isActive={(match) => match?.path?.startsWith("/CustomersList/")}
                className="mr-4 py-2  text-bold"
              >
                {translate.ComanyMedicalRequest}
              </NavLink>


              <NavLink
                exact={true}
                to="/team-management/users"
                activeClassName="is-active"
                className="mr-4 py-2 text-bold"
              >
                {translate.teamManagement}
              </NavLink>

              <NavLink
                exact={true}
                to="/seting"
                activeClassName="is-active"
                className="mr-4 py-2 text-bold"
              >
                {translate.setting}
              </NavLink>

{/* 
              <NavLink
                exact={true}
                to="/Payments"
                activeClassName="is-active"
                className="mr-4 py-2 text-bold"
              >
                {translate.Payments}
              </NavLink> */}

              <NavLink
                exact={true}
                to="/Servics"
                activeClassName="is-active"
                className="mr-4 py-2 text-bold"
              >
                {translate.services}
              </NavLink>

            </div>


          </div>
        </nav>
        </div>
      </>
    );
  } else {
    return (
      ''
    )
  }
}
export default HeaderCheckMe;
