
import React from "react";
import Locale from "../../../translations";

import Traveler from "./traveler";

export default function ListTravelers() {
  const { translate } = Locale;

  // call api for progress

  // call api for checked

  return (
    <>
       <table className="payments table table-travelers mt-4">
        <thead className="payments-header ">
          <tr className="table_head">
            <th scope="col">الدفعة</th>
            <th scope="col">التاريخ</th>
            <th scope="col">المبلغ المستحق</th>
            <th scope="col">الحالة</th>
            
          </tr>
        </thead>
        <tbody >
          <Traveler />
          <Traveler />
          <Traveler />
          
          
        </tbody>
      </table>
      
    </>
  );
}
