 import React, { lazy } from 'react'
import { Redirect, Route } from "react-router-dom";
import { useCompanyState } from '../context/global';
import HeaderAdmin from './layout/HeaderAdmin';

export default function AdminRouter({ component: Component, ...props }) {
	let CompanyState  = useCompanyState();
	if (CompanyState.isAuth) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
          <>
            <HeaderAdmin />
						<Component {...matchProps} />
          </>
				)}
			/>
		);
	}else{
			return <Redirect to="/admin/login" />;
		} 
	
	
}
 