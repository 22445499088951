import React, { useEffect, useState } from "react";
import Locale from "../../../../translations";
import { useHistory, useParams } from 'react-router';
import { listTravellersById } from "../../../../services/yarvel";
import scan from "../../../../assets/images/barcod.png"
export default function ViewOPeration() {
  const { translate } = Locale;
  let history = useHistory();

//listTravellersById
const [employee, setEmployee] = useState([]);
let { id } = useParams();
useEffect(() => {

  async function fetchData() {
    const Employees = await listTravellersById(id);
    
    setEmployee(Employees.data);
    console.log("listTravellersById is ",Employees.data)
    setTimeout(() => {
      if (Employees.status===200) {
        window.print();
      }
    }, 1000);
  }
  fetchData();
  
}, []);


  return (
    <div className="container p-0">
      <div className="my-4 d-flex justify-content-between">
        <div>
        <h3 className="font-weight-bold">امر تشغيل فحص PCR     </h3>
        <h3 >  مسؤول التشغيل : {employee?.data?.operationOrder_employee} </h3>
        </div>
        <img src={scan} width="150" height="100" />
      </div>

      
      <div className="bg-white px-5 py-4">
        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.orderData}
        </h5>

        <div className="instructions py-3 p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.orderNumber}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.reservation_no}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.ResultTimeResult}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.check_date_period} {translate.hour}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.timeRequest}
          </p>
          <p className="col-4 h5 py-1">{(employee?.data?.request_date)?.slice(0,10)} | {(employee?.data?.request_date)?.slice(11,16)}</p>
        </div>

        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.CustomerData}
        </h5>

        <div className="instructions py-3 p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.nameArabic}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.traveller_name_ar}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.nationalId}
          </p>
          <p className="col-4 h5 py-1">   {employee?.data?.national_id  }  </p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.EnglishName}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.traveller_name_en}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.passportNumber}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.passport_number}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.Nationality}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.nationality}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.phoneNumber}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.phone}</p>
        </div>

        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.TripData}
        </h5>

        <div className="instructions py-3 p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.destnation}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.trip?.destination?.names?.ar}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.departureDate}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.trip?.arrival_date}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.Transporter}
          </p>
          <p className="col-4 h5 py-1"> {employee?.data?.trip?.transporter?.ar}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.departureDate}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.trip?.departure_date}</p>
        </div>

        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.ExaminationData}
        </h5>

        <div className="instructions py-1 p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.examinationStatus}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.test_type}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.ServiceGrade}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.grade}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.Transporter}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.reservation_type}</p>
        </div>

        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.Cost}
        </h5>

        <div className="instructions py-3 p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.TotalCost}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.total_cost} {translate.pound}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.Paid}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.paid} {translate.pound}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.PaymentType}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.payment_status}</p>

          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.resit}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.remain_cost} {translate.pound}</p>
        </div>
      




      <button className=" btn btn-secondary px-5 my-4 font-weight-bold" onClick={() => {
              history.push("/CustomersList/orders");
              }}> {translate.back}</button>
    </div>




     
      



      
    </div>

 
    
  );
}
