

function Traveler() {
   

    return (
      <>
        <tr>
          <td>1</td>
          <td>12-10-2021 </td>
          <td>10000 جنيه</td>
          <td className="text-success">تم استلامة </td>
            
       
         
        </tr>
     </>
    );
}

export default Traveler;
