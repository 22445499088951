import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router';
import { listTravellersById } from "../../../../services/yarvel";
import Locale from "../../../../translations";

export default function ViewCheched() {
  const { translate } = Locale;
  let history = useHistory();
  const baseURL = process.env.REACT_APP_API_URL;

  /**************************** */
  const [employee, setEmployee] = useState([]);
  let { id } = useParams();
  useEffect(() => {
    async function fetchData() {
      const res = await listTravellersById(id);
      setEmployee(res?.data?.data);
      //console.log("listTravellersById is ",res.data)
    }
    fetchData();
    
  
    
  }, []);
  

/**************************** */
  return (
    <div className="container p-0">
      <header className=" list_header border py-3 px-2 d-flex justify-content-between align-items-baseline mt-4">
        <h3 className="text-muted font-weight-bold">
        فحص PCR
        </h3>
      </header>

      <div className="bg-white px-5 py-4">
        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.orderData}
        </h5>

        <div className="instructions py-3 p-2 row">
          <p className="col-3 h5  py-1 font-weight-bold">
            {translate.orderNumber}
          </p>
          <p className="col-3 h5 py-1">{employee?.reservation_no}</p>
          <p className="col-3 h5 py-1 font-weight-bold">
            {translate.ResultTimeResult}
          </p>
          <p className="col-3 h5 py-1">{employee?.check_date_period} {translate.hour}</p>
          <p className="col-3  h5 py-1 font-weight-bold">
            {translate.timeRequest}
          </p>
          <p className="col-3 h5 py-1">{(employee.request_date)?.slice(0,10)} | {(employee.request_date)?.slice(11,16)}</p>

          <p className="col-3  h5 py-1 font-weight-bold">
          مسؤول التشغيل
          </p>
          <p className="col-3 h5 py-1">{employee.operationOrder_employee} </p>


          <p className="col-3  h5 py-1 font-weight-bold">
          تاريخ / وقت امر التشغيل
          </p>
          <p className="col-3 h5 py-1">{(employee.operationOrder_created_at)?.slice(0,10) } | {(employee.operationOrder_created_at)?.slice(11,16)}</p>

          <p className="col-3  h5 py-1 font-weight-bold">
          الوقت المستغرق
          </p>
          <p className="col-3 h5 py-1">{employee.check_date_period } {translate.hour}</p>
        </div>

        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.CustomerData}
        </h5>

        <div className=" instructions py-3 p-2 row">
            
          <div className="row ">
            <p className="col-3 h5  py-1 font-weight-bold">
              {translate.nameArabic}
            </p>
            <p className="col-3 h5 py-1">{employee.traveller_name_ar}</p>
            <p className="col-3 h5 py-1 font-weight-bold">
              {translate.nationalId}
            </p>
            <p className="col-3 h5 py-1">{employee.national_id} </p>
            <p className="col-3  h5 py-1 font-weight-bold">
              {translate.birthDay}
            </p>
            <p className="col-3 h5 py-1">{employee?.birthDay}08/04/1990</p>
            <p className="col-3  h5 py-1 font-weight-bold">
              {translate.passportNumber}
            </p>
            <p className="col-3 h5 py-1">{employee?.passport_number}</p>
            <p className="col-3  h5 py-1 font-weight-bold">
              {translate.analysisResults}
            </p>
            <p className="col-3 h5 py-1"> {employee?.result=="positive"?"ايجابى":"سلبى" } </p>
            <p className="col-3  h5 py-1 font-weight-bold">
              {translate.reportResult}
            </p>
            {/* */}
            <p className="col-3 h5 py-1"><a href={`${employee?.attachment}`} target="_blank"  className="btn text-primary"><i className="fas fa-eye fa-2x"></i></a></p>
           
            </div>
        </div>

        {/* *************************************************************************/}
       

        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.TripData}
        </h5>

        <div className="instructions py-3 p-2 row">
          <p className="col-3 h5  py-1 font-weight-bold">
            {translate.destnation}
          </p>
          <p className="col-3 h5 py-1">{employee?.trip?.destination?.names?.ar}</p>
          <p className="col-3 h5 py-1 font-weight-bold">
            {translate.departureDate}
          </p>
          <p className="col-3 h5 py-1">{employee.trip?.arrival_date}</p>
          <p className="col-3  h5 py-1 font-weight-bold">
            {translate.Transporter}
          </p>
          <p className="col-3 h5 py-1">{employee.trip?.transporter?.ar} </p>
          <p className="col-3  h5 py-1 font-weight-bold">
            {translate.departureDate}
          </p>
          <p className="col-3 h5 py-1">{employee.trip?.departure_date}</p>
        </div>

        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.ExaminationData}
        </h5>

        <div className="instructions py-3 p-2 row">
          <p className="col-3 h5  py-1 font-weight-bold">
            {translate.examinationStatus}
          </p>
          <p className="col-3 h5 py-1">{employee.test_type}</p>
          <p className="col-3 h5 py-1 font-weight-bold">
            {translate.ServiceGrade}
          </p>
          <p className="col-3 h5 py-1">{employee.grade}</p>
          <p className="col-3  h5 py-1 font-weight-bold">
            {translate.Transporter}
          </p>
          <p className="col-3 h5 py-1">{employee.reservation_type}</p>
        </div>

        {/* *************************************************************************/}
        <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.Cost}
        </h5>

        <div className="instructions py-3 p-2 row">
          <p className="col-3 h5  py-1 font-weight-bold">
            {translate.TotalCost}
          </p>
          <p className="col-3 h5 py-1">{employee.amount} {translate.pound}</p>
          <p className="col-3 h5 py-1 font-weight-bold">
            {translate.Paid}
          </p>
          <p className="col-3 h5 py-1">{employee.amount} {translate.pound}</p>
          

          
        </div>
      </div>




      <button className=" btn btn-secondary px-5 my-4 font-weight-bold" onClick={() => {
              history.push("/CustomersList/checked");
              }}> {translate.back}</button>
    </div>

 
    
  );
}
