import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { editSettings, showDataSettings } from "../../../services/yarvel";
import Locale from "../../../translations";
import SettingOperation from "./settingOperation";
import SpecialSettings from "./specialSettings";

export default function Setting(){
    const { translate } = Locale;
    const [setting, setSetting] = useState({capacities:[]})
        
    useEffect(() => {
        async function fetchData() {
        const res = await showDataSettings();
        if (res?.status==200) {
          setSetting(res?.data?.data)
        }
      }
      fetchData();
    }, []);

//console.log("settingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsettingsetting",setting);
    const edit=(async ()=>{
  //console.log('settingsettingsettingsettingsettingsetting',setting);
      const CapicitiesData=
      setting.capacities.length>0 ? setting.capacities.map((capacity,index)=>{
      //debugger
               return (     {
                          "area_ids":capacity.area_ids&& capacity.area_ids.filter(res=>res.hasOwnProperty('names')).length>0 ? capacity.area_ids.map(res=>res.id): capacity.area_ids.map(res=>res.value),//capacity?.area_ids[index]?.value
                          "capacity":capacity?.capacity,
                          "city_id":capacity?.city_id.hasOwnProperty('value')?capacity?.city_id?.value:capacity?.city_id,
                        })
        }) :{}
        //debugger
      const res = await editSettings({...setting,capacities:[...CapicitiesData ]});
      if (res.status == 201) {
        // setSettings(res?.data);
        //setDataSettings(res?.data);
        store.addNotification({
          title: "info!",
          message: translate.updatedSuccessfully,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
        // setToggle(!toggle)
      }
    })

    return (
        <>
        <div className="container p-0 my-4">
            <header className=" list_header border py-2 px-2 d-flex justify-content-between align-items-baseline">
              <h3 className="text-muted font-weight-bold">{translate.settingOperations}</h3>
            </header>
          <SettingOperation   setting={setting}  setSetting={setSetting}  />


      
            <header className=" list_header border py-2 px-2 mt-3 d-flex justify-content-between align-items-baseline">
              <h3 className="text-muted font-weight-bold">{translate.SpecialSettings}</h3>
            </header>
          <SpecialSettings  setting={setting}  setSetting={setSetting} />  
       
          <div className="d-flex justify-content-end">
          <button className="btn btn-success btn-lg px-5 mt-3 " onClick={edit}>{translate.SaveChanges}</button>
        </div>
        </div>





 
        </>
    );
}