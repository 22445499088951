import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "../../../../components/shared/DatePicker";

import TextField from "../../../../components/shared/textField";
import Locale from "../../../../translations";
//import { listTravellers } from "../../../services/yarvel";
//import { cleanEmpty } from "../../../../services/general";

export default function Filter({submit, filter ,setfilter}) {
  const { translate } = Locale;
  const filterEmpty=((filter)=>{
    setfilter({
      name:"", 
      departure_date: "",
      check_date: "",

    })
    submit();
    
  })
//console.log("filter",filter);
  return (
    <div className="row p-3 bg-white no-gutters list_header bg-white ">
      <div className="col-3 mr-2">
        <TextField
          hasLabel={false}
          label={translate.search}
          type={"text"}
          value={filter?.name}
          placeholder={translate.searchName}
          name="name"
          onChange={(e) =>{
            setfilter({
              ...filter,
              name: e.target.value,
            })
          }
        }
        >
          <i className="fas fa-search"></i>
        </TextField>
      </div>
      <div className="col-3 mr-2">
        <DatePicker
           hasLabel={false} 
           placeholder={translate.DataOrder}
           date={filter?.departure_date}
           name="departure_date"
           
           onChangeDate={(e) =>{
            setfilter({
              ...filter,
              departure_date: e,
            })
          }
        }
        />
      </div>

      <div className="col-3 mr-2">
        <DatePicker
          hasLabel={false}
          placeholder={translate.examinationDate}
          name="check_date"
          date={filter?.check_date}
          onChangeDate={(e) =>{
            setfilter({
              ...filter,
              check_date: e,
            })
          }
          
            
          }
        />
      </div>

   {/*
      <div className="col mr-2">
     
        <SelectField
          hasLabel={false}
          onFocus={true}
          label={translate.analysisResults}
          placeholder={translate.statusCheck}
        />
        
      </div>
*/}

      <div className="">
        <button className="btn btn-primary  text_filed px-5 py-2 font-weight-bold" onClick={submit}>
          {translate.search}
        </button>
        <button className="btn text-muted font-weight-bold " onClick={()=>filterEmpty(filter)} >  
          {" "}
          <i className="fas fa-retweet" ></i>
          {translate.reset}
        </button>
      </div>
    </div>
  );
}
