import React from "react";
import { Table } from "reactstrap";

import Locale from "../../../../translations";
import PaymentFilter from "./paymentFilter";
import PaymentList from "./paymentList";
import PaymentCost from "./paymentCost";

export default function payment() {
  const { translate } = Locale;
  /** */

  const users = [
    {
      id: 1,
      name: "company_user",
      email: "companyUser010@email",
      phone_number: "1038745834",
      status: "active",
    },
    {
      id: 2,
      name: "company_user",
      email: "companyUser010@email",
      phone_number: "1038745834",
      status: "pending",
    },
    {
      id: 3,
      name: "company_user",
      email: "companyUser010@email",
      phone_number: "1038745834",
      status: "active",
    },
  ];

  const AllUsers =
    users && users.length > 0
      ? users.map((user, index) => <PaymentList user={user} index={index} />)
      : "";

  /** */

  return (
    <div className="container p-0">

      <PaymentCost />
      <div className="d-flex justify-content-between mt-5 mb-2">
          <h3 className="text-muted font-weight-bold">{translate.Payments}</h3>
          
            <button
              type="button"
              className="btn btn-success px-5 py-2  font-weight-bold "
            >
              {translate.Settlement}
            </button>
          
        </div>
      <PaymentFilter />
      <div className="  p-0">
        <Table>
          <thead>
            <tr className="text-grey">
              <th>كود المعاملة</th>
              <th> المبلغ</th>
              <th>التاريخ</th>
              <th>نوع المعاملة</th>
              <th>الموظف</th>
            </tr>
          </thead>
          <tbody className="table-stripe ">{AllUsers}</tbody>
        </Table>

        <div></div>
      </div>
    </div>
  );
}
