import React from "react";
import { NavLink} from "react-router-dom";
import Locale from "../../../translations";
import Filter from "./filter";
import ListTravelers from "./listTravelers";
function paymentsList() {
  const { translate } = Locale;
 
  return (
    <section>
      <div className="container p-0 my-4">
        
        <h3 className="text-muted font-weight-bold mt-5"> {translate.Payments}</h3>
        <header className=" list_header border py-3 px-2  ">
          <Filter />
        </header>
       
        <ListTravelers/>
       
      </div>

     
    </section>
  );
}

export default paymentsList;
