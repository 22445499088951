import React, { useEffect, useState } from "react";
import Locale from "../../../translations";
import {  useHistory } from "react-router-dom";
import Filter from "./filter";

import { AdminchangeStatus, listAdminFilter } from "../../../services/admin";
import ListComanyMedical from "./listComanyMedical";
import { store } from "react-notifications-component";
function OrdersList() {
  const { translate } = Locale;
  let history = useHistory();
  /**Filter  here */

  const [filter, setfilter] = useState({
    search: "",
    page:1
    
  });
  const [searching, setSearching] = useState(false);
  const [allData, setAllData] = useState([]);
  const [meta, setMeta] = useState([]);

  const submit = () => {
    setSearching(!searching);
    //setfilter({search: ""})
  };

  const emptyInputs = () => {
    setSearching(!searching);
    setfilter({search: ""})
  };
  
  useEffect(() => {
    async function fetchListClients() {

      const res = await listAdminFilter(filter.page,{
        ...filter,
      });
      setAllData(res?.data?.data);
      setMeta(res?.data?.meta)

    }
    fetchListClients();
  }, [searching,filter]);

  const changeStatusUser = async (id, status) => {
    const res = await AdminchangeStatus(id, status);
    if (res.status === 200) {
      store.addNotification({
        title: "info!",
        message: "Successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });

      if (res.status === 200) {
        setSearching(!searching);
      }
    }
  };
  
  /**Filter here */

  return (
    <section>
      <div className="container p-0 my-4">
        <header className=" list_header border py-2 px-2 d-flex justify-content-between align-items-baseline">
          <h3 className="text-muted font-weight-bold">
            {translate.ComanyMedical}
          </h3>
          <button
            className="btn btn-success px-4"
            onClick={() => {
              history.push(`/admin/ComanyMedical/addCompany`);
            }}
          >
            {translate.add}{" "}
            <i class="fas fa-plus-circle" style={{ color: "white" }}></i>
          </button>
        </header>
        <Filter submit={submit} emptyInputs={emptyInputs} filter={filter} setfilter={setfilter} />
        <ListComanyMedical allData={allData} changeStatusUser={changeStatusUser} filter={filter} setfilter={setfilter} setAllData={setAllData} meta={meta} setMeta={setMeta} />
      </div>
    </section>
  );
}

export default OrdersList;
