import React,{useState} from "react";
import Locale from "../../../translations";
import { useHistory } from "react-router-dom";
import UserNumber from "./userNumber"
import Filter from "./filter";
import { Link, NavLink } from "react-router-dom";
import Users from "./users"

function WorkTeam() {
  const { translate } = Locale;
  let history = useHistory();
  const [users, setUsers] = useState([]);
  const [searching, setSearching] = useState(false)
 
  const submit = () => {
    setSearching(!searching);
  }
  console.log("searchingsearchingsearchingsearching",searching);
  const countOfUsers = (val) => {
    setUsers(val);
    submit();
  };

/**api */
const [filter, setfilter] = useState({
  name:"",
  status:""
  
});

//ListUser

  return (
    <section>
      <UserNumber users={users}/>
      <div className="container p-0">
        <div className="d-flex justify-content-between mt-5 mb-2">
          <h3 className="text-muted font-weight-bold">{translate.teamManagement}</h3>
          <NavLink
            exact={true}
            to={`/team-management/users/add`}
            activeClassName="is-active"
          >
            <button
              type="button"
              className="btn btn-success px-4 py-2 font-weight-bold "
              onClick={() => {
                history.push("/team-management/users/add");
              }}
            >
              <i className="fas fa-plus-circle mr-2"></i>
              {translate.addUser}
            </button>
          </NavLink>
        </div>
        <header className="d-flex justify-content-between align-items-center list_header border py-3 px-2  ">
          <Filter filter={filter} setfilter={setfilter} users={users} setUsers={setUsers} searching={searching} setSearching={setSearching}/>
          <div>
            <NavLink
              exact={true}
              to={`/team-management/users`}
              activeClassName="is-active"
              className="btn-nav text-bold mx-1 px-3 bg-brwon rounded text-muted"
            >
              {translate.users}
            </NavLink>
            <NavLink
              exact={true}
              to={`/team-management/groups`}
              activeClassName="is-active"
              className="btn-nav text-bold mx-1 px-3 bg-brwon rounded text-muted"
            >
              {translate.groups}
            </NavLink>
          </div>
        </header>
        <Users countUsers = {(val)=>countOfUsers(val)} searching={searching} setSearching={setSearching} filter={filter} setfilter={setfilter} submit={submit}/>
      </div>
    </section>
  );
}

export default WorkTeam;
