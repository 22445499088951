import axios from "axios";
import { cleanEmpty } from "./general";
const baseURL = process.env.REACT_APP_API_URL_ADMIN;

const [protocol, domain] = baseURL.split("//");

const host = window.location.host;
const subdomain = host != null ? host.split(".")[0] : null;
//debugger
//const URL = `http://${subdomain}.${domain}/api`;

export const AdminURL = `https://${domain}/api/central`

//*****************************Admin Login************************

export const singin = async (data) => {
	//debugger
	//console.log('AdminURL',AdminURL);
  return await axios
    .post(`${AdminURL}/auth/login`, data)
    .then((res) => res)
    .catch((err) => err.response);
};
//*****************************Admin************************

//labs/dashboard
export const dashboard = async () => {
	return await axios
	.get(`${AdminURL}/home`)
	.then((res) => res)
	.catch((err) => err.response);

};
export const listAdmin = async () => {
	return await axios
	.get(`${AdminURL}/companies`)
	.then((res) => res)
	.catch((err) => err.response);

};
///api/central/companies?search=&status
export const listAdminFilter = async (page = 1,filter) => {
	return await axios
	.get(`${AdminURL}/companies?page= ${page}`,{
		params: cleanEmpty(filter)
	  })
	.then((res) => res)
	.catch((err) => err.response);

};

export const AdminchangeStatus = async (id, status) => {
	return await axios
		.patch(`${AdminURL}/companies/${id}/${status}`) 
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewCompany = async (id) => {
	return await axios
	.get(`${AdminURL}/companies/${id}`)
	.then((res) => res)
	.catch((err) => err.response);

};

export const addCompany = async (data) => {
	return await axios
		.post(`${AdminURL}/companies`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchCountries = async () => {
	return await axios
	  .get(`${AdminURL}/lookups/countries`)
	  .then((res) => res.data.data)
	  .catch((err) => err.response);
  };

  export const fetchCities = async (id) => {
	return await axios
		.get(`${AdminURL}/lookups/cities?country_id=${id}`)
		.then((res) => res.data.data)
		.catch((err) => err.response);
};
//companies
export const companiesDrop = async () => {
	return await axios
		.get(`${AdminURL}/companies/create`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editCompany = async (id,data) => {
	return await axios
	.put(`${AdminURL}/companies/${id}`,data)
	.then((res) => res)
	.catch((err) => err.response);

};

/*******************Orders*********************** */
export const listIcomingOrder = async (id,filter) => {
	return await axios
	.get(`${AdminURL}/companies/${id}/travellers`,{
		params: cleanEmpty(filter)
	  })
	.then((res) => res)
	.catch((err) => err.response);

};
export const viewIcomingOrder = async (id,index) => {
	return await axios
	.get(`${AdminURL}/companies/${id}/travellers/${index}`)
	.then((res) => res)
	.catch((err) => err.response);

};
//companies/1/travellers/pending
export const listPendingOrder = async (id,filter) => {
	return await axios
	.get(`${AdminURL}/companies/${id}/travellers/pending`,{
		params: cleanEmpty(filter)
	  })
	.then((res) => res)
	.catch((err) => err.response);

};

//companies/1/travellers/checked?name=&departure_date&check_date&result&result_date

export const listCheckedOrder = async (id,filter) => {
	return await axios
	.get(`${AdminURL}/companies/${id}/travellers/checked`,{
		params: cleanEmpty(filter)
	  })
	.then((res) => res)
	.catch((err) => err.response);

};