import React , { useState }from "react";
import { NavLink } from "react-router-dom";

import Locale from "../../../translations";

import { Collapse, Button, CardBody, Card } from 'reactstrap';
import { Table } from 'reactstrap';


const Collaps = (props) => {
    const { translate } = Locale;
    const { Group } = props;
    let [Id, setId] = useState("");
    const [collapse, setCollapse] = useState(false);
    const [status, setStatus] = useState('Closed');

    const onEntering = () => setStatus('Opening...');

    const onEntered = () => setStatus('Opened');

    const onExiting = () => setStatus('Closing...');

    const onExited = () => setStatus('Closed');

    const toggle = (event) =>{ 
        Id=event.target.id;
        setCollapse(!collapse);
        console.log("id is ",Id)
    } ; 
      console.log(Group);

  return (
    <div>
        <div className=" col-containt">
            <div>
            <Button className="CollapsePlusIcon" color="primary" id="btn1" onClick={(e)=>toggle(e)} style={{ marginBottom: '1rem' }}>
              <i  className={collapse ? "fas fa-minus" : "fas fa-plus"}></i>
            </Button> 
              {Group.name}
            </div>

            <div>
                <span className="num">{Group.users.length}</span>  {translate.users} 
            </div>

            <div className="last editIcon">
            {/* <NavLink
                exact={true}
                to={`/team-management/groups/edit/`+Group.id}
              >
                <i class="fas fa-edit"></i>  
               { translate.edit}  
              </NavLink>      */}
            </div>

        </div>
      
    
      <Collapse
        isOpen={collapse}
        onEntering={onEntering}
        onEntered={onEntered}
        onExiting={onExiting}
        onExited={onExited}
      >
        <Card>
          <CardBody className="card-body">
          <Table className="table" >
            <thead className="text-grey" > 
                <tr>
                <th> { translate.userName}</th>
                <th>{ translate.userEmail}</th>
                <th>{ translate.status}</th>
                </tr>
            </thead>
            <tbody className="table-stripe ">
            {Group.users && Group.users.length>0 ?Group.users.map(res=>{
              return <tr>
              <td>{res.name}</td>
              <td>{res.email}</td>
              <td > 
              {res.status && res.status=='active'  ?  <span className="text-green">{translate.active}</span>  :   <span className="text-danger"> {translate.deactive}  </span> }
              </td>
              </tr>
            }) :(
              <tr>
                <td colSpan="10" className="text-center my-3">
                  <div className="product-build__product-no-data">
                    <i className="fas fa-info-circle fa-lg"></i>{" "}
                    <h4>{translate.noResult}</h4>
                  </div>
                </td>
              </tr>
            ) }
              
            </tbody>
         </Table>
          </CardBody>
        </Card>
      </Collapse>

    </div>
  );
}


export default Collaps;

