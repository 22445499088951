import React from "react";
import Locale from "../../../../translations";

export default function PaymentList(props) {
	const { user } = props;
	//const { Labs } = Locale;
	const { translate } = Locale;


	//console.log(props);
	return (
		<>



<tr>
          <td>{user.name}</td>
          <td>{user.email}</td>
          <td>{user.phone_number}</td>
          
          <td> {user.status}</td>
          <td>{user.phone_number} </td>
        </tr>
		
		</>
	);
}
