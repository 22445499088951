
import React, { createContext, useContext, useReducer } from "react";
import reducers from "./reducers";

const CompanyContext = createContext(undefined);

const CompanyDispatchContext = createContext(undefined);

const currentLanguage = localStorage.getItem("currentLanguage");
const token = localStorage.getItem("token");
const isAuth = localStorage.getItem("isAuth");
const isAdmin = localStorage.getItem("isAdmin");
const LabsContext = createContext(undefined);


// intial state to be passed to reducer 
const initialState = {
  currentLanguage :currentLanguage?? "ar",
  token : token ?? undefined,
  isAuth : isAuth ?? false,
  isAdmin : isAdmin ?? false,
};



// to get data from context  
function useCompanyState() {
  const context = useContext(CompanyContext);
  if (context === undefined) {
    throw new Error("context is undefined");
    
  }
  return context;
}


 function useLabsState() {
	const context = useContext(LabsContext);
	if (context === undefined) {
		throw new Error("useLabsState must be used within a LabsProvider");
	}
	return context;
}
// to dispatch actions store dispatch function in context to see
// in components and change reducer state passed to company context
function useCompanyDispatch() {
  const context = useContext(CompanyDispatchContext);
  if (context === undefined) {
    throw new Error(
      "Dispatch is undefined"
    );
  }
  return context;
}



// provider for all components to see context stored  data
// reducer to manage data 
// pass reducer state to context

function CompanyProvider({ children }) {
  const [state, dispatch] = useReducer(reducers, initialState);
  return (
    <CompanyContext.Provider value={state}>
      <CompanyDispatchContext.Provider value={dispatch}>
        {children}
      </CompanyDispatchContext.Provider>
    </CompanyContext.Provider>
  );
}

export { CompanyProvider, useCompanyState, useCompanyDispatch,useLabsState };


