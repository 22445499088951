import React ,{ useState,useEffect }  from 'react'
import { useHistory } from 'react-router';

import { useCompanyState } from "../../../../context/global";

import { createOperationOrder, listEmployees, listTravellers } from "../../../../services/yarvel";
import Locale from '../../../../translations';


function Traveler({traveler,toggleModalShow }) {
    let history = useHistory();
    
    const { translate } = Locale;
   //console.log("asd",traveler, "Ew=",traveler?.data?.test_type)
    return (
      <>
      <tr>
        <td>  {traveler?.reservation_no} </td>
        <td>{traveler?.test_type}</td>
        <td>
        {traveler?.traveller_name?.ar} <br/>
        {traveler?.traveller_name?.en}
        </td>
        <td>{traveler.reservation_type} </td>
        
        {/* <td>2</td> */}
       
        <td>
        <div className="d-flex flex-column">
            <span> {(traveler.check_date)?.slice(0,10)} </span>
            <span>{(traveler.check_date)?.slice(11,16)}  </span>
          </div>
        </td>
        <td>
          <div className="d-flex flex-column">
            <span>{(traveler.request_date)?.slice(0,10)} </span>
            <span>{(traveler.request_date)?.slice(11,16)}</span>
          </div>
        </td>
        <td>
          <button className="text-primary btn-link mr-3 font-weight-bold" onClick={() => {

            history.push(`/CustomersList/view-result/${traveler.id}`);
          }}><i class="fas fa-eye" style={{fontSize:"16px"}}></i>  {translate.view}</button>

          <button className="text-primary btn-link font-weight-bold " onClick={()=>{
            toggleModalShow(traveler.id)
            
            }}><i class="fas fa-print" style={{fontSize:"16px"}}></i>  {translate.SupplyingRunningOrder}</button>

        </td>


 {/* Modal examainaton type */}
 <div>

</div>
      </tr>





</>
    );
}

export default Traveler;
