import { useEffect, useState } from "react";
import { store } from "react-notifications-component";

import { editSettings } from "../../../services/yarvel";
import Checkbox from "../../../components/shared/checkbox";
import TextField from "../../../components/shared/textField";
import validate from "../../../helpers/validate";
import Locale from "../../../translations";
import SpecialSettings from "./specialSettings";

export default function SettingOperation({setting,setSetting}){
    const { translate } = Locale;


const [errors, setErrors] = useState({});
const checkFormErrors = () => {
  setErrors({
    ...errors,
    ...validate(
      { name: "capacity", value: setting.capacity },
      { required: true }
    ),
    ...validate(
      { name: "from_hour", value: setting.from_hour },
      { required: true }
    ),
    ...validate(
      { name: "to_hour", value: setting.to_hour },
      { required: true}
    ),
  });
};






/****settings*******/
const days = [
  "saturday",
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
];


const isAllDaysChecked = setting?.work_days && setting.work_days.length === 7;

const handleAllDays = (e) => {
  const checked = e.target.checked;
  const value = e.target.value;
  if (checked) {
    setSetting({ ...setting, work_days: days });
  } else {
    setSetting({ ...setting, work_days: [] });
  }
  
};

const handleDays = (e) => {
  const checked = e.target.checked;
  const value = e.target.value;
  const day = days[value];
  if (checked) {
    setSetting({ ...setting, work_days: [...setting?.work_days, day] });
  } else {
    const work_daysClone = [...setting.work_days];
    const filtered = work_daysClone.filter((d) => d !== day);
    setSetting({ ...setting, work_days: filtered });

  }
};
/***********Edit */
//editSettings






/*End Api */
    return (
        <>
        <div className="container py-5  bg-white">
        

        
        <div className="checks d-flex  m-auto">

            <h3 className="mr-3">{translate.workDays}  </h3>

            <div className="check-content  d-flex justify-content-around mx-5">
            <Checkbox label={translate.allDays} 
             onChange={handleAllDays}
             checked={isAllDaysChecked}/> 
                    {days &&
										!isAllDaysChecked &&
										
										days.map((day, i) => {
											return (
												<div key={day} className="day">
													<Checkbox
														id={days[i]}
														text={days[i]}
													  value={i}
                            checked={setting?.work_days?.includes(day)}
														name="check"
														label={days[i]}
														onChange={handleDays}
													/>
												</div>
											);
										})}

            </div>
            
          </div>

          <div className="controls d-flex  align-items-center  mx-auto mt-5" >
          <h3 className="mr-3">{translate.OperatingHours}</h3>
          <TextField
              type="time"
              name="from_hour"
              hasLabel={true}
              label={translate.from}
              errors={errors.from_hour}
							color={errors.from_hour?.required ? "danger" : ""} 
              
              onChange={(e) => {
                setSetting({
                ...setting,
                from_hour: e.target.value,
              })
            }}
            value={setting?.from_hour} /**dataSettings?.data?   (settings?.from_hour)?.slice(11,16)*/
              
            >
            {/* <i className="far fa-clock"></i> */}
            </TextField>
          <div className="mx-5">
           <TextField
              
              type="time"
              name="to_hour"
              hasLabel={true}
              label={translate.to}
              errors={errors.to_hour}
							color={errors.to_hour?.required ? "danger" : ""}
              onChange={(e) => {
                setSetting({
                  ...setting,
                  to_hour: e.target.value,
                })
              }}
              value={setting?.to_hour} /**dataSettings?.data?  (settings?.to_hour)?.slice(11,16)*/
            >
            {/* <i className="far fa-clock"></i> */}
            </TextField>
            </div>
          </div>
          


      </div>







      {/* <div className="d-flex justify-content-end">
          <button className="btn btn-success btn-lg px-5 mt-3 " onClick={edit}>{translate.SaveChanges}</button>
        </div> */}
        </>
    );
}